.Whats-not-covered-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 3%;
    width: 100%;
  }

  .Whats-not-covered-first-box {
    margin-bottom: -16px;
    padding-top: 10px;
    text-align: center;
    width: 72%;
  }

  .Whats-not-covered-second-box {
    width: 72%;
  }

  .Whats-not-covered-second-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0;
  }

  .Whats-not-covered-title {
    color: var(--deep-blue-main);
    display: flex;
    font-family: 'IvyPresto-Regular';
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    justify-content: flex-start;
    letter-spacing: -0.4px;
    line-height: 31px;
    margin: 0;
    text-align: center;
  }

  .Whats-not-covered-description {
    color: var(--deep-blue-shade-2);
    font-family: 'IvyEpic-Regular';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
  }

  .Whats-not-covered-item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 14px 14px 14px 0px;
  }

  .Whats-not-covered-item p {
    align-items: center;
    color: var(--deep-blue-shade-2);
    display: flex;
    font-family: 'IvyEpic-Regular';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 2px 0 0 0;
  }

  .Whats-not-covered-boxitem {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 6px;
  }

  /* Mobile */
@media (max-width: 570px) {
  .Whats-not-covered-title {
    font-size: 24px;
  }

  .Whats-not-covered-first-box,
  .Whats-not-covered-second-box {
    width: 95%;
  }
}
