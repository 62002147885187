.Discount-applied-container,
.Discount-applied-container-your-info {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding-bottom: 32px;
  width: 72%;
}

.Discount-applied-container-your-info {
  width: 100%;
}

.Discount-tag-icon {
  padding-right: 8px;
  height: 20px;
}

.Discount-percentage {
  margin-right: 8px;
}

.Discount-applied-title {
  color: var(--deep-blue-main);
  font-family: 'IvyPresto-Regular';
  font-size: 36px;
  margin: 0;
  padding-bottom: 9px;
}

.Discount-applied-items {
  flex-wrap: wrap;
  justify-content: center;
}

.Discount-applied-item {
  color: var(--deep-blue-shade-2);
  font-family: 'IvyEpic-Regular';
  padding: 0 16px 8px 0;
}

/* Mobile */
@media (max-width: 568px) {
  .Discount-applied-container {
    padding-bottom: 0;
    width: 96%;
  }

  .Discount-applied-container-your-info {
    padding-bottom: 0;
  }

  .Discount-applied-items {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .Discount-applied-title {
    font-size: 24px;
  }

  .Discount-applied-item {
    font-size: 14px;
    padding: 0 0 12px 0;
  }

  .Discount-tag-icon {
    padding-right: 4px;
  }
}
