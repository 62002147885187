.Modal-error-button {
  background-color: var(--deep-blue-main);
  border-radius: 8px;
  border: none;
  cursor: pointer;
  height: 60px;
  margin-top: 25px;
  width: 352px;
}

.Modal-error-button-text {
  color: var(--ivory-shade-3);
  font-family: 'IvyEpic-Bold';
  font-size: 16px;
}

.Modal-error-container {
  align-items: center;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 367px;
  width: 480px;
}

.Modal-error-description {
  color: var(--deep-blue-shade-2);
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  height: 72px;
  line-height: 24px;
  margin-top: 12px;
  text-align: center;
  width: 349px;
}

.Modal-error-clouds-image {
  height: 69.03px;
  margin-top: 32px;
  width: 127.58px;
}

.Modal-error-title {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Bold';
  font-size: 22px;
  line-height: 32px;
  margin-top: 16px;
  text-align: center;
}

/* Mobile */
@media (max-width: 568px) {
  .Modal-error-container {
    padding-bottom: 40px;
  }

  .Modal-error-button {
    width: 19em;
  }

  .Modal-error-description {
    height: auto;
    width: 16em;
  }
}
