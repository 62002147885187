.Effective-date-custom-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  margin: 30px 0;
}

.Effective-date-text {
  color: #000;
  font-family: 'Inter-Regular';
  font-size: 14px;
}

.Effective-date-custom-button {
  background-color: #fff;
  border-color: #eceef0;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  font-family: 'Inter-Regular';
  font-size: 14px;
  height: 40px;
  margin-top: 8px;
  outline-color: #2fb3b4;
  padding: 10px;
  text-align: start;
  text-indent: 3px;
  width: 130px;
}

.Effective-date-custom-button:focus {
  border: 1px solid #bec1c4;
}

.react-datepicker__close-icon {
  right: 0;
  top: 4px;
}

.react-datepicker__close-icon::after {
  background-color: #2fb3b4;
}

.react-datepicker__navigation--next {
  border-left-color: #000;
}

.react-datepicker__navigation--previous {
  border-right-color: #000;
}

.react-datepicker-popper {
  z-index: 4;
}
