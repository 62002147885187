.Payment-breakdown-container {
  width: 100%;
}

.Payment-breakdown-title {
  color: var(--main-text);
  display: flex;
  font-family: 'IvyPresto-Regular';
  font-size: 36px;
  letter-spacing: -0.4px;
  line-height: 46px;
  margin: 0 0 24px 0;
  text-align: left;
}

.Payment-breakdown-breed {
  font-family: "IvyEpic-Regular";
  font-size: 14px;
  line-height: 21px;
  margin: 0;
}

.Payment-breakdown-pet-row-container {
  margin: 0 0 33px 0;
  padding: 0;
}

.Tag-pet-cover {
  padding-right: 16px;
}

.Payment-breakdown-pet-tag-container {
  height: 48px;
  position: relative;
  width: 73px;
}

.Tag-pet-cover {
  height: 48px;
  width: 48px;
}

.Payment-breakdown-pet-info {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 9px;
  width: 100%;
}

.Payment-breakdown-pet-info-left {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: flex-start;
}

.Payment-breakdown-hidden {
  display: none !important;
}

.Payment-breakdown-expandable-container {
  margin: auto;
  width: 100%;
}

.Payment-breakdown-expandable {
  display: flex;
  gap: 16px;
  padding: 0 24px;
}

.Payment-breakdown-column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.Payment-breakdown-list-title,
.Payment-breakdown-list-item {
  color: var(--deep-blue-base-100);
  font-size: 14px;
}

.Payment-breakdown-list-title {
  border-bottom: 1px solid var(--mint-base-100);
  font-family: "IvyEpic-Bold";
  padding-bottom: 2px;
}

.Payment-breakdown-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 4px;
  margin: 0;
  padding: 0;
}

.Payment-breakdown-list-item {
  display: flex;
  font-family: "IvyEpic-Regular";
  justify-content: space-between;
  line-height: 20px;
}

.Payment-breakdown-list-item[data-bold=true] {
  font-family: "IvyEpic-Bold";
}

.Payment-breakdown-list-item[data-toBottom=true] {
  align-items: flex-end;
  flex: 1;
}

.Payment-breakdown-pet-tag {
  position: absolute;
  top: 5px;
}

.Payment-breakdown-action {
  background: transparent;
  margin: 0;
  padding: 8px;
  width: 16px;
}

.Payment-breakdown-pet-name {
  font-family: "IvyEpic-Bold";
  font-size: 18px;
  color: var(--deep-blue-base-100);
}

.Payment-breakdown-toggle {
  background: transparent;
  cursor: pointer;
  padding: 0;
}

/* Tablet - Medium */
@media (max-width: 710px) {
  .Payment-breakdown-title {
    margin-left: 0;
  }
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Payment-breakdown-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .Payment-breakdown-title {
    margin-left: 18px;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Payment-breakdown-pet-row-container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 0 33px 0;
    padding: 0;
    width: 100%;
  }

  .Tag-pet-cover {
    height: 40px;
    padding: 0;
    width: 38px;
  }

  .Full-width-mobile {
    width: 100%;
  }

  .Payment-breakdown-title {
    font-size: 24px;
    margin: 16px 16px 24px 0;
  }

  .Payment-breakdown-expandable-container {
    margin: auto;
    width: 85%;
  }

  .Payment-breakdown-pet-info{
    margin: 0;
    padding-top: 0;
    width: 100%;
  }

  .Payment-breakdown-expandable {
    padding: 0;
    flex-direction: column;
  }
}
