.Fake-checkbox,
.Fake-checkbox-placeholder
{
  height: 20px;
  position: relative;
  top: 4px;
  width: 20px;
}

.Fake-checkbox-placeholder {
  background-color: #888d95;
  border-radius: 100%;
}
