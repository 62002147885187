.Modal-forget-password-confirm-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 305px;
  width: 381px;
}

.Modal-forget-password-confirm-success-icon {
  height: 64px;
  margin-top: 32px;
  width: 64px;
}

.Modal-forget-password-confirm-title {
  color: #22435e;
  height: 30px;
  margin-top: 16px;
}

.Modal-forget-password-confirm-subtitle {
  color: #3d484d;
  height: 23px;
  margin-top: 16px;
}

.Modal-forget-password-confirm-close-button {
  background-color: #2fb3b4;
  border-radius: 8px;
  color: #fff;
  height: 60px;
  margin-top: 32px;
  width: 150px;
}
