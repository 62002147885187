.Modal-live-vet-subscription-bubbles-image {
  height: 56px;
  margin-top: 40px;
  width: 77px;
}

.Modal-live-vet-subscription-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 391px;
  max-width: 480px;
  padding-bottom: 16px;
}

.Modal-live-vet-subscription-description {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  min-height: 48px;
  line-height: 24px;
  max-width: 416px;
  margin: 0 32px;
  padding: 24px 16px;
  text-align: center;
}

.Modal-live-vet-subscription-primary-button {
  background-color: var(--deep-blue-main);
  border-radius: 8px;
  border: none;
  cursor: pointer;
  height: 60px;
  margin-top: 24px;
  max-width: 352px;
  width: 90%;
}

.Modal-live-vet-subscription-primary-button-text {
  color: var(--ivory-shade-3);
  font-family: 'Inter-SemiBold';
  font-size: 16px;
  line-height: 19px;
}

.Modal-live-vet-subscription-secondary-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 24px;
  margin-top: 16px;
}

.Modal-live-vet-subscription-secondary-button-text {
  color: var(--deep-blue-shade-2);
  font-family: 'Inter-SemiBold';
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.Modal-live-vet-subscription-subtitle {
  color: var(--deep-blue-shade-3);
  font-family: 'IvyEpic-Bold';
  font-size: 18px;
  line-height: 27px;
}

.Modal-live-vet-subscription-title {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Bold';
  font-size: 22px;
  line-height: 32px;
  margin-top: 16px;
}
