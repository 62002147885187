.Temporary-message-bar {
  align-items: center;
  background-color: #bbc6d3;
  display: flex;
  height: auto;
  justify-content: center;
  max-height: 100vh;
  overflow-y: auto;
  padding: 4px 32px;
  position: relative;
}

.Temporary-message-bar p {
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #293742;
}

.Temporary-message-bar button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  position: absolute;
  right: 10px;
  top: 10px;
}

.Temporary-message-bar-close-icon {
  height: 12px;
  width: 12px;
}

.Temporary-message-sticky {
  position: sticky;
  width: calc(100% - 64px);
  z-index: 10;
}
