.Switch-active-container {
  background-color: #20c2c9;
  transition: all 0.2s ease-out;
}

.Switch-ball {
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 0.5px 1px 0.2px #797979;
  height: 20px;
  left: 0;
  margin-top: 1px;
  position: absolute;
  width: 20px;
}

.Switch-button-container {
  border-radius: 57.14px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 24px;
  position: relative;
  width: 40px;
}

.Switch-container {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.Switch-inactive-container {
  background-color: var(--deep-blue-shade-3);
  transition: all 0.2s ease-out;
}

.Switch-label {
  color: #3d484d;
  font-size: 14px;
  margin-left: 13.5px;
}

.Switch-position-active {
  animation: moveToRight 0.1s alternate;
  left: 17px;
}

.Switch-position-inactive {
  animation: moveToLeft 0.1s alternate;
  left: 2px;
}

@keyframes moveToLeft {
  0% { left: 25px; }
  100% { left: 0px; }
}

@keyframes moveToRight {
  0% { left: 0px; }
  100% { left: 25px; }
}
