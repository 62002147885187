.Parents-review-container {
  align-items: center;
  display: flex;
  gap: 30px;
  justify-content: center;
  margin: 38px auto 32px;
  width: 83%;
}

.Parents-review-title {
  margin: 45px auto 32px;
  max-width: 639px;
}

.Parent-review {
  align-items: center;
  background-color: var(--deep-blue-main);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 110px;
}

.Parents-review-container > img {
  flex: 1;
  max-width: 585px;
}

.Parent-review-quote {
  color: var(--citron-main);
  flex: 1;
  font-family: 'IvyEpic-Regular';
  font-size: 22px;
  line-height: 32px;
  margin: 94.32px 0 59px;
  max-width: 365px;
  text-align: center;
}

.Parent-review > img {
  width: 56.23px;
}

.Parent-review-parent {
  color: var(--blue-main);
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  text-align: center;
  white-space: break-spaces;
}

/* Tablet - Big */
@media (max-width: 1200px) and (min-width: 711px) {
  .Parents-review-container {
    gap: 17.22px;
    width: 89.59%;
  }

  .Parents-review-title {
    margin-top: 48px;
    max-width: 450px;
  }

  .Parent-review {
    padding: 28.71px 0;
  }

  .Parents-review-container > img,
  .Parent-review {
    flex: 1;
    max-width: 335.89px;
  }

  .Parent-review-quote {
    font-size: 12.63px;
    line-height: 18.37px;
    margin: 54.16px 60px 33.49px;
    width: 209px;
  }

  .Parent-review > img {
    width: 32.29px;
  }

  .Parent-review-parent {
    font-size: 9.19px;
    line-height: 13.78px;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Parents-review-container {
    flex-direction: column;
    gap: 25px;
    width: 86.6%;
  }

  .Parents-review-title {
    margin-top: 48px;
  }

  .Parents-review-container > img {
    width: 100%;
  }

  .Parent-review {
    padding: 45px 0 39px;
    width: 100%;
  }

  .Parent-review > img {
    width: 45px;
  }

  .Parent-review-quote {
    font-size: 22px;
    line-height: 32px;
    margin: 39px auto 38px;
    width: 84%;
  }
}
