.Modal-wrapper {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.Modal-wrapper-hidden {
  display: none;
}

.Modal-wrapper:has(.Modal-add-pet-content ) {
  align-items: baseline;
}

.Modal-backdrop {
  background-color: rgba(0, 0, 0, 0.20);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.Card-container {
  align-items: center;
  background-color: var(--ivory-shade-3);
  border-radius: 12px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.20);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 100vh;
  overflow: auto;
  position: relative;
}

.Modal-card-title {
  color: #22435e;
  flex: 1;
  font-family: 'Inter-SemiBold';
  font-size: 24px;
  margin-bottom: 14px;
  margin-top: 32px;
  text-align: center;
}

.Modal-card-title-divider {
  background-color: #7c52bf;
  height: 2px;
  width: 96px;
}

.Modal-card-icon-close {
  height: 28px;
  width: 28px;
}

.Modal-card-button-close {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 14px;
  top: 14px;
}

.Modal-card-title-header {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@keyframes modalAppear {
  from {
    bottom: -150vh;
  }

  to {
    bottom: 0;
  }
}

@keyframes modalDisappear {
  from {
    bottom: 0;
  }
  
  to {
    bottom: -150vh;
  }
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Modal-card-title {
    font-family: 'Inter-Bold';
    font-size: 20px;
    margin: 32px 0 8px 0;
    min-height: 30px;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Card-container {
    width: 90%;
  }

  .Multi-modal-card-container {
    animation-duration: 0.4s;
  }

  .Modal-card-title {
    font-family: 'Inter-Bold';
    font-size: 20px;
    margin: 32px 0 10px 0;
  }
}
