.Save-your-quote-button-container {
  align-items: center;
  color: var(--deep-blue-main);
  display: flex;
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  font-weight: 400;
  justify-content: center;
  padding-bottom: 16px;
  padding-top: 24px;
  position: relative;
}

/* Mobile */
@media (max-width: 568px) {
  .Save-your-quote-button-container {
    color: var(--deep-blue-main);
    font-size: 14px;
    justify-content: center;
    margin: 0 0 16px;
    padding-top: 24px;
    padding: 24px 0 0 0;
  }
}
