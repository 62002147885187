.Selector-pills-container {
  align-items: flex-start;
  border: none;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0;
  padding: 0 19px;
}

.Selector-pills-title {
  color: var(--deep-blue-main);
  display: flex;
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  margin-bottom: 3px;
}

.Selector-pills-options {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 4px;
}

/* Mobile */
@media(max-width: 568px) {
  .Selector-pills-container {
    padding: 0 15px 0;
  }
}
