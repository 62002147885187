
.Prop103-disclaimer-text {
  color: var(--deep-blue-shade-2);
  font-family: 'IvyEpic-Regular';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin: 0 0 33px 64px;
  padding-right: 64px;
  text-align: justify;
}

.Prop103-disclaimer-text a {
  color: var(--deep-blue-main);
  font-weight: 700;
}
