.Duplicated-purchases-modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 280px;
}

.Duplicated-purchases-modal > h1 {
  color: var(--deep-blue-main);
  font-family: "Inter-Bold";
  font-size: 22px;
}

.Duplicated-purchases-modal-text {
  color: var(--deep-blue-shade-2);
  font-size: 16px;
}

.Duplicated-purchases-modal-text > b {
  text-decoration: underline;
  text-underline-offset: 3px;
}

.Duplicated-purchase-modal-actions {
  display: flex;
  gap: 8px;
  margin-top: 32px;
}

.Duplicated-purchases-modal-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0;
  margin: 24px 0 32px;
  padding: 0;
  width: 95%;
}

.Duplicated-purchases-modal-list-duplicated {
  margin: 24px 0;
}

.Duplicated-purchases-modal-list-new {
  margin: 16px 0 24px;
}

.Duplicated-purchases-modal-pet {
  color: var(--deep-blue-shade-1);
  display: flex;
  font-family: "IvyEpic-Regular";
  font-size: 12px;
  gap: 16px;
  line-height: 21px;
}

.Duplicated-purchases-modal-pet > img {
  width: 38px;
}

.Duplicated-purchases-modal-pet > div {
  display: flex;
  flex-direction: column;
}

.Duplicated-purchases-modal-pet-name {
  color: var(--deep-blue-main);
  font-family: "IvyEpic-Bold";
  font-size: 16px;
  line-height: 27px;
  transform: translateY(2px);
}
