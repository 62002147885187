.Card-plan-header-container {
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.02);
  display: flex;
  height: 64px;
  justify-content: center;
  margin-top: 20px;
  width: 91.3%;
}

.Brand-logo-image-text {
  align-items: center;
  background-color: rgb(217, 222, 225, .3);
  border-color: rgb(176, 187, 191, .3);
  border-radius: 7px;
  border-style: solid;
  border-width: 1px;
  color: #3d484d;
  display: flex;
  font-family: 'Inter-Medium';
  font-size: 14px;
  height: 40px;
  justify-content: center;
  margin-left: 12px;
  width: 138px;
}

.Brand-logo-image-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  margin-right: 24px;
}

.Brand-logo-image {
  height: 61.78%;
  max-width: 100%;
}

.Card-plan-header-text-right {
  align-items: center;
  border-radius: 7px;
  color: #22435e;
  display: flex;
  font-family: 'IvyEpic-Bold';
  font-size: 16px;
  height: 40px;
  min-width: 171px;
}

.Card-plan-header-container-landing {
  display: none;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Card-plan-header-container-landing {
    display: unset;
  }

  .Card-plan-header-container {
    display: flex !important;
    margin: 24px auto 0;
    width: 92%;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Brand-logo-image-container {
    height: 35.29px;
    margin: 0;
    width: auto;
  }

  .Brand-logo-image {
    height: 100%;
    max-width: 100%;
  }

  .Card-plan-header-container {
    background-color: var(--ivory-shade-2);
    flex-direction: column;
    height: fit-content;
    margin-top: 32px;
  }

  .Card-plan-header-text-right {
    justify-content: center;
  }
}
