.Copay-card-container, .Copay-card-container-selected {
  align-items: center;
  background-color: #ffffffbf;
  border-radius: 18px;
  border: 2px solid #eceef0;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 178px;
  justify-content: center;
  margin-top: 40px;
  min-height: 178px;
  min-width: 529px;
  position: relative;
  width: 529px;
}

.Copay-card-container-selected {
  background-color: var(--blue-shade-3);
  border-color: var(--deep-blue-main);
  border-style: solid;
  border-width: 2px;
}

.Copay-icon {
  height: 40px;
  position: absolute;
  top: -23px;
  width: 40px;
}

.Copay-top-row {
  display: flex;
  flex-direction: row;
  height: 24px;
  margin-bottom: 16px;
  width: 100%;
}

.Copay-title {
  color: var(--deep-blue-main);
  font-family: "IvyEpic-Regular";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin: 0 15px 0 24px;
  width: 414px;
}

.Copay-switch {
  margin-right: 13px;
  width: 44px;
}

.Copay-added {
  background-color: var(--deep-blue-main);
}

.Copay-bottom-row {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 77.89px 0 24px;
}

.Copay-description {
  color: var(--deep-blue-shade-2);
  font-family: "IvyEpic-Regular";
  font-size: 12px;
  height: 54px;
  line-height: 18px;
  width: 420px;
}

.Copay-how-it-works-container {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  margin-top: 17px;
  padding: 0;
}

.Copay-info-icon {
  height: 16px;
  width: 16.15px;
}

.Copay-how-it-works-text > .Powerup-see-whats-covered-link {
  color: var(--deep-blue-main);
  font-family: "IvyEpic-Bold";
  font-size: 12px;
  padding: 0;
}

/* Mobile */
@media (max-width: 568px) {
  .Copay-card-container, .Copay-card-container-selected {
    height: 228px;
    min-height: 228px;
    min-width: 97%;
    width: 97%;
  }

  .Copay-top-row {
    min-height: 57px;
    width: 97%;
  }

  .Copay-title {
    font-size: 14px;
    height: 100%;
    line-height: 19px;
    margin: 0 41px 0 16px;
    width: 54%;
  }

  .Copay-bottom-row {
    margin: 0;
    width: 88%;
  }

  .Copay-description {
    font-family: "Inter-Regular";
    font-size: 12px;
    height: 80px;
    line-height: 16px;
    width: 256px;
  }
}
