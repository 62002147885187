.Subsection-title {
  color: #22435e;
  font-family: 'IvyPresto-Regular';
  font-size: 36px;
  margin: 104px 0 64px;
  text-align: center;
}

/* Tablet - Big */
@media (max-width: 1200px) and (min-width: 711px) {
  .Subsection-title {
    margin-top: 48px;
    padding: 0 12%;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Subsection-title {
    font-size: 36px;
    line-height: 1.167;
    margin: 48px 0;
    padding: 0 4%;
  }
}
