.Live-vet-card-container {
  align-items: center;
  background-color: var(--ivory-shade-2);
  border-radius: 12px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 24px 0;
  width: 736px
}

.Live-vet-card-container h3 {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Bold';
  font-size: 22px;
  margin-bottom: 8px;
}

.Live-vet-card-text {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Regular';
  font-size: 18px;
  line-height: 27px;
  margin: 0;
}

.Live-vet-card {
  background-color: var(--ivory-shade-3);
  border-radius: 8px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 32px;
  padding: 32px 32px 40px;
  width: 608px;
}

.Live-vet-card-content {
  width: 73%;
}

.Live-vet-card-content h4 {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Bold';
  font-size: 22px;
  line-height: 32px;
  margin: 0;
}

.Live-vet-card-content p {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Regular';
  line-height: 24px;
  margin: 16px 0 0;
}

.Live-vet-card-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Live-vet-price {
  align-items: flex-start;
  color: var(--deep-blue-main);
  display: flex;
  font-family: 'IvyEpic-Bold';
  font-size: 36px;
  justify-content: flex-end;
}

.Live-vet-price-small {
  font-size: 22px;
}

.Live-vet-button {
  align-items: center;
  background-color: var(--deep-blue-main);
  border-radius: 30px;
  border-style: none;
  color: var(--ivory-shade-3);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: 'Inter-SemiBold';
  font-size: 14px;
  height: 40px;
  justify-content: center;
  line-height: 16.94px;
  outline: var(--blue-main) 1px;
  padding: 0 12.5px;
}

.Live-vet-button img {
  margin-left: 8px;
}

/* Tablet - Medium */
@media (max-width: 710px) {
  .Live-vet-card,
  .Live-vet-card-container {
    width: 95%;
  }
}


/* Mobile */
@media (max-width: 568px) {
  .Live-vet-card {
    flex-direction: column;
    margin-top: 24px 0 0 0;
    padding: 24px 24px 33px;
    width: 85%;
  }

  .Live-vet-card-container {
    border-radius: 18px;
    margin: 40px auto 0;
    padding: 24px 16px;
    width: 80%;
  }

  .Live-vet-card-container h3 {
    font-size: 18px;
    margin: 0 0 8px 0;
    text-align: center;
    width: 90%;
  }

  .Live-vet-card-text {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    width: 100%;
  }

  .Live-vet-card-content {
    width: 100%;
  }

  .Live-vet-card-content h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .Live-vet-card-content p {
    margin: 8px 0 0;
  }

  .Live-vet-card-actions {
    align-items: center;
    flex-direction: row;
    margin-top: 32px;
  }

  .Live-vet-button {
    margin-bottom: 16px;
    min-width: 130px;
  }
}
