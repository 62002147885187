.Pet-protect-copay-modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 433px;
  padding: 28px;
}

.Pet-protect-copay-modal > img {
  height: 60px;
  margin-bottom: 16px;
  width: 60px;
}

.Pet-protect-copay-title {
  color: #22435e;
  font-family: 'Inter-Semibold';
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 24px;
}

.Pet-protect-copay-subtitle {
  color: #1e2226;
  font-family: 'Inter-Medium';
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
}

.Pet-protect-copay-modal > p {
  color: #3d484d;
  font-family: 'Inter-Regular';
  font-size: 13px;
  margin-bottom: 32px;
  width: 350px;
}

.Pet-protect-modal-confirm {
  background-color: #b8ede8;
  border-radius: 8px;
  border: none;
  color: #182f43;
  cursor: pointer;
  font-family: 'Inter-Semibold';
  margin-bottom: 24px;
  padding: 20px 37.5px;
  width: 90%;
}

.Pet-protect-modal-nevermind {
  color: #1e2226;
  cursor: pointer;
  font-family: 'Inter-Medium';
  font-size: 16px;
}

/* Mobile */
@media (max-width: 568px) {
  .Pet-protect-copay-modal {
    padding: 16px 16px 32px 16px;
  }

  .Pet-protect-copay-title {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
  }

  .Pet-protect-copay-subtitle {
    font-size: 14px;
  }

  .Pet-protect-copay-modal > p {
    font-size: 12px;
    margin-bottom: 16px;
    width: 256px;
  }

  .Pet-protect-modal-confirm {
    font-family: 'Inter-Semibold';
    margin-bottom: 14px;
    padding: 16px 20.5px;
    width: 100%;
  }

  .Pet-protect-modal-nevermind {
    font-size: 14px;
  }
}
