.Get-quote-button {
  background-color: var(--citron-main);
  border-radius: 111px;
  color: var(--main-text);
  cursor: pointer;
  font-family: 'IvyEpic-Bold';
  font-size: 14px;
  height: 42px;
  width: 145px;
}

/* Mobile */
@media (max-width: 568px) {
  .Get-quote-button {
    font-size: 14px;
    height: auto;
    padding: 11.5px 0;
    width: 78%;
  }
}
