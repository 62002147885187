.Live-vet-parent-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 608px;
}

.Live-vet-parent-title {
  color: var(--deep-blue-main);
  font-family: 'IvyPresto-Regular';
  font-size: 36px;
  margin-bottom: 32px;
  margin-top: 24px;
  text-align: center;
}

.Live-vet-parent-input-container {
  display: flex;
  flex-direction: column;
  min-height: 82px;
  margin: 0 16px;
  position: relative;
  width: 288px;
}

.Live-vet-parent-state-input-container {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  min-height: 72px;
  width: 164px;
}

.Live-vet-parent-zip-input-container {
  display: flex;
  flex-direction: column;
  min-height: 72px;
  width: 108px;
}

.Live-vet-parent-input-row {
  display: flex;
}

.Live-vet-parent-zip-state-container {
  display: flex;
  margin: 0 16px;
}

.Live-vet-parent-input-label {
  color: var(--main-text);
  display: flex;
  font-family: 'IvyEpic-Bold';
  font-size: 14px;
  line-height: 21px;
  margin-left: 4px;
}

.Live-vet-parent-input {
  background-color: transparent;
  border-color: var(--deep-blue-shade-2);
  border-radius: 0.5px;
  border-style: none none solid none;
  border-width: 1px;
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  line-height: 24px;
  outline: none;
  padding: 6px;
}

.Live-vet-parent-label-error {
  color: #cc0000;
  font-family: 'Inter-Medium';
  font-size: 11px;
  margin-top: 2px;
  padding-left: 7px;
}

.Live-vet-parent-input-error {
  border-color: var(--poppy-main);
}

.Live-vet-parent-input:focus {
  border-color: var(--blue-shade-dark);
}

.Live-vet-parent-input:hover:not(:focus):not(:disabled) {
  border-color: #7e888c;
  border-image: none;
  border-radius: 0.5px;
  border-style: none none solid none;
  border-width: 1px;
}

.Live-vet-parent-input:disabled {
  background-color: #f8f8f8;
  border-radius: 5px;
  border: none;
}

.Live-vet-parent-input::placeholder {
  color: var(--hinted-text);
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
}

.Live-vet-parent-input-icon-container {
  background-color: #d9dee1;
  border-radius: 5px;
  display: flex;
  padding: 8px 0 8px 8px;
}

.Live-vet-parent-input-with-icon {
  background-color: #d9dee1;
  border: none;
  color: var(--deep-blue-shade-2);
  font-family: 'Inter-Medium';
  font-size: 15px;
  line-height: 24px;
  min-width: 0px;
}

.Live-vet-parent-lock-icon {
  margin-right: 13px;
}

.Live-vet-parent-dropdown-icon {
  height: 10px;
  margin-right: 13px;
  width: 16px;
}

.Second-parent-button-container {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 32px 0 20px;
  outline-color: #2fb3b4;
}

.Second-parent-plus-icon {
  height: 20px;
  margin-right: 8px;
  width: 20px;
}

.Second-parent-form-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
}

.Second-parent-brief-description {
  color: #3d484d;
  line-height: 1.5;
  margin-bottom: 32px;
  text-align: center;
  width: 544px;
}

.Employee-id-info-live-vet {
  align-self: center;
  display: flex;
  margin-left: 8px;
  margin-top: -5px;
}

.Employee-id-info-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 16px;
  outline-color: #2fb3b4;
  position: absolute;
  right: 20px;
  top: 25px;
  width: 16px;
}

.Employee-id-info-button img {
  pointer-events: none;
  width: 16px;
}


/* Tablet - Big */
@media (min-width: 568px) and (max-width: 1400px) {
  .Live-vet-parent-container {
    width: 100%;
  }

  .Live-vet-parent-input-row {
    flex-wrap: wrap;
    justify-content: center;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Employee-id-info-button img {
    height: 14px;
    width: 16px;
  }
  
  .Live-vet-parent-container {
    width: 85%;
  }

  .Live-vet-parent-input-row {
    flex-direction: column;
    width: 100%;
  }

  .Live-vet-parent-zip-state-container {
    justify-content: center;
    margin-bottom: 16px;
  }

  .Live-vet-parent-input-container {
    margin: 0;
    width: 100%;
  }
}
