.App-presentation {
  --phone-height: 703px;
  --phone-width: 362px;
  margin: 0 auto;
  max-width: 1250px;
  position: relative;
  width: 83.47%;
}

.App-presentation-title {
  max-width: 550px;
  text-align: left;
}

.App-presentation-text {
  color: var(--main-text);
  font-family: 'IvyEpic-Regular';
  font-size: 18px;
  line-height: 27px;
  margin: 0 auto;
  max-width: 700px;
}

.App-presentation-images-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 58px 120px;
  margin-top: 38px;
  position: relative;
  width: 58.98%;
}

.App-presentation-intro {
  width: 51%;
}

.App-presentation-phone {
  position: absolute;
  right: var(--phone-width);
  top: 70px;
}

/* Tablet - Big */
@media (max-width: 1200px) and (min-width: 711px) {
  .App-presentation {
    --phone-height: 498px;
    --phone-width: 249px;
    padding-top: 1px;
  }

  .App-presentation-intro {
    width: 50.45%;
  }

  .App-presentation-title {
    font-size: 50px;
    line-height: 50px;
    max-width: 331px;
    padding: 0;
  }

  .App-presentation-text {
    margin: 0;
    max-width: 92%;
  }

  .App-presentation-phone {
    right: var(--phone-width);
    top: -35px;
  }

  .App-presentation-images-container {
    gap: 37px 78px;
    margin-top: 160px;
    width: 100%;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .App-presentation {
    --phone-height: 608px;
    --phone-width: 304px;
  }

  .App-presentation-intro {
    padding-top: 7px;
    width: 100%;
  }

  .App-presentation-title {
    margin-bottom: 21px;
    padding: 0 5px;
  }

  .App-presentation-text {
    font-size: 14px;
    line-height: 1.6;
    margin: 0 5px 24px;
    width: calc(96.5% - 10px);
  }

  .App-presentation-phone {
    position: static;
  }

  .App-presentation-images-container {
    flex-wrap: nowrap;
    gap: 0 29px;
    margin-top: 44px;
    overflow-y: scroll;
    width: 100%;
  }
}
