.Question-container {
  border-bottom: 1px solid #01313d38;
  padding-bottom: 30px;
}

.Question {
  align-items: center;
  background-color: transparent;
  color: var(--main-text);
  cursor: pointer;
  display: flex;
  font-family: 'IvyEpic-Bold';
  font-size: 22px;
  justify-content: space-between;
  outline: 1px solid var(--blue-shade-1);
  padding: 0;
  text-align: left;
  width: 100%;
}

.Question img {
  transition: all 0.5s ease-in-out;
}

.Question[aria-expanded=false] img {
  transform: rotate(180deg);
}

.Question-answer {
  color: var(--main-text);
  line-height: 24px;
  max-width: 90%;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.Question-answer a {
  color: var(--main-text);
}

.Question-answer p {
  margin: 0;
}

.Question-answer p + p {
  margin-top: 1em;
}

.Question-answer strong {
  font-family: 'IvyEpic-Bold';
}

.Question-container + .Question-container {
  margin-top: 30px;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Question-answer {
    padding-top: 4px;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Question-answer {
    padding-top: 14px;
  }
}
