.Login-account-screen-main-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.Login-account-screen-header {
  background-color: var(--deep-blue-main);
  min-height: 240px;
  position: relative;
  width: 100%;
}

.Login-account-screen-background-color {
  background-color: var(--deep-blue-main);
}

.Login-account-screen-figo-logo {
  height: 64px;
  left: 72px;
  position: relative;
  top: 24px;
  width: 161px;
}

.Login-account-screen-main-content {
  align-items: center;
  background: no-repeat url('../../assets/login/stain-background.png');
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.Login-account-screen-card {
  align-items: center;
  background-color: #fbf6f1;
  border-radius: 12px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.20);
  display: flex;
  flex-direction: column;
  margin-top: -99px;
  max-width: 474px;
  min-height: 494px;
  width: 100%;
  z-index: 1;
}

.Login-account-screen-card > h3 {
  color: var(--deep-blue-main);
  font-family: "IvyEpic-Regular";
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  margin: 40px 0 0 0;
  text-align: center;
}

.Login-account-screen-card > span {
  color: #293742;
  font-family: "IvyEpic-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 16px 44px 0 44px;
  text-align: center;
}

.Login-account-screen-form {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.Login-account-screen-input-container,
.Login-account-screen-input-container-margin-top {
  width: 98.5%;
}

.Login-account-screen-input-container {
  background-color: #fbf6f1;
}

.Login-account-screen-input-container-margin-top {
  margin-top: 24.33px;
}

.Login-account-screen-input {
  background-color: #fbf6f1;
  font-family: "IvyEpic-Regular";
  font-size: 16px;
  font-weight: 400;
  padding-left: 10px;
  width: 98.5%;
}

.Login-account-screen-input-title {
  font-family: "IvyEpic-Regular";
  font-size: 14px;
  font-weight: 700;
}

.Login-account-screen-form-submit-button {
  background-color: var(--deep-blue-main);
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  font-family: "Inter-SemiBold";
  font-size: 16px;
  height: 60px;
  margin-top: 32.33px;
  transition: all 0.3s ease;
  width: 342px;
}

.Login-account-screen-form-continue {
  align-items: center;
  background-color: #fbf6f1;
  color: #293742;
  cursor: pointer;
  display: flex;
  font-family: "IvyEpic-Regular";
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  line-height: 24px;
  margin: 25px 0 40px 0;
  transition: all 0.3s ease;
}

.Login-account-screen-arrow {
  margin-left: 10px;
  transform: rotate(-90deg);
  transition: all 0.5s ease;
  width: 14px;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Login-account-screen-figo-logo {
    height: 54px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 141px;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Login-account-screen-header {
    min-height: 220px;
  }

  .Login-account-screen-figo-logo {
    width: 90px;
    height: 36px;
  }

  .Login-account-screen-form {
    width: 90%;
  }

  .Login-account-screen-card > span {
    margin: 24px 16px 0 16px;
  }

  .Login-account-screen-form-submit-button {
    width: 100%;
  }
}

/* Very specific case */
@media (max-width: 470px) {
  .Login-account-screen-card {
    border-radius: 0;
    box-shadow: none;
  }

  .Questions-section-login {
    padding: 48px 0;
    margin: 0;
  }
}
