.Your-info-main-container {
  background-color: var(--ivory-40);
  border-radius: 12px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  height: 100%;
  padding-bottom: 38px;
  margin-bottom: 40px;
  margin-top: -88px;
  position: relative;
  width: 736px;
}

.Your-info-scrolled {
  z-index: 1;
}

.Your-info-step-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 672px;
  padding: 32px;
}

.Your-info-container-company-header {
  align-items: center;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Your-info-step-gone {
  display: none;
}

.Your-info-ills-container {
  bottom: -100px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 350px;
  width: 1344px;
}

.Your-info-ills-justify {
  justify-content: space-around;
  top: 200px;
}

.Your-info-ill-container {
  display: flex;
}

.Your-info-ill-container:nth-child(odd) {
  justify-content: flex-end;
}

/* Tablet */
@media (min-width: 568px) and (max-width: 1200px) { 
  .Your-info-main-container {
    border-radius: 0;
    margin-top: 0;
    padding-bottom: 32px;
    z-index: 1;
  }

  .Your-info-container-company-header {
    display: none;
  }
}


/* Tablet - Big */
@media (max-width: 1200px) {
  .Your-info-main-container {
    margin-bottom: 0;
    padding-top: 32px;
    padding: 0px 0 40px;
    width: 100%;
  }

  .Your-info-ills-container {
    display: none;
  }
}

/* Tablet - Medium */
@media (max-width: 710px) {
  .Your-info-main-container {
    width: 100%;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Your-info-main-container {
    border-radius: 0;
    margin-top: 0;
    width: 100%;
  }
  
  .Your-info-container-company-header {
    display: none;
  }

  .Your-info-step-container {
    justify-content: center;
  }
}
