.Landing-how-works-steps {
  display: flex;
  flex-wrap: wrap;
  gap: 26px;
  justify-content: center;
  margin: 64px auto 80px;
}

/* Tablet - Big */
@media (max-width: 1200px) and (min-width: 711px) {
  .Landing-how-works-steps {
    gap: 28.57px;
    margin: 64px auto 48px;
    width: 100%;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Landing-how-works-steps {
    flex-direction: column;
    gap: 23px;
    margin: 24px auto 48px;
    width: 90%;
  }
}
