.Loading-overlay-container {
  height: 222px;
  padding: 30px 30px 15px 30px;
  width: 300px;
}

/* Mobile */
@media (max-width:568px) {
  .Loading-overlay-container {
    height: auto;
    padding: 0;
    width: 100%;
  }
}
