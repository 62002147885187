.Card-plan-main-container {
  --card-backgroud: var(--ivory-40);
  align-items: center;
  background-color: var(--card-backgroud);
  border-radius: 12px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: -88px;
  width: 736px;
}

.Card-plan-main-container-enrollment {
  --card-backgroud: var(--ivory-40);
  align-items: center;
  background-color: var(--card-backgroud);
  border-radius: 12px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 48px;
  margin-top: -88px;
  width: 736px;
}

.Card-plan-header-title {
  color: var(--deep-blue-main);
  font-family: 'IvyPresto-Regular';
  font-size: 36px;
  font-weight: normal;
  line-height: 46px;
  margin: 40px 0 0 0;
  overflow: hidden;
  text-align: left;
  display: flex;
  width: 72%;
}

.Card-plan-footer-continue-button-icon {
  height: 20px;
  margin-left: 24px;
  width: 10px;
}

.Card-plan-footer-email-your-quote-button {
  background-color: transparent;
  color: var(--deep-blue-main);
  cursor: pointer;
  font-family: 'IvyEpic-Bold';
  font-size: 14px;
  font-weight: 700;
}

.No-desktop {
  display: none;
}

.Card-plan-header-title-container {
  background-color: var(--card-backgroud);
  border-radius: 12px 12px 0 0;
  display: flex;
  justify-content: center;
  width: 100%;
}

.Card-plan-header-title-container > h1 {
  max-width: 100%;
}

.Card-plan-main-container-elevated {
  z-index: 10;
}

.App-card-plan-main-container {
  position: relative;
}

.Card-plan-main-container-margin-top {
  margin-top: -76px;
  border-radius: 12px 12px 0 0;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Card-plan-main-container {
    margin-top: 0;
  }

  .Card-plan-main-container-enrollment {
    margin-bottom: 0px;
    margin-top: 0;
    width: 100%;
  }

  .App-card-plan-main-container {
    align-items: center;
    background-color: var(--ivory-shade-3);
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .App-card-plan-main-container-bg {
    background-color: transparent;
  }

  .No-desktop {
    display: none;
  }

  .No-tablet {
    display: none;
  }
}

/* Tablet - Medium */
@media (max-width: 710px) {
  .Card-plan-main-container {
    align-self: center;
    width: 95%;
  }

  .Card-plan-main-container-enrollment{
    align-self: center;
    margin-bottom: 0px;
    width: 95%;
  }

  .Quoting-header-my-quote {
    display: none;
  }

  .Quoting-header-container-steps-container {
    margin-right: 3%;
  }

  .Card-plan-header-title {
    width: 96%;
  }
}


/* Mobile */
@media (max-width: 568px) {
  .App-card-plan-main-container {
    background-color: var(--ivory-40);
    padding-bottom: 40px;
  }

  .Card-plan-main-container {
    border-radius: 12px 12px 0 0;
    box-shadow: unset;
    margin: 0;
    padding: 0 10px;
  }

  .Card-plan-main-container-enrollment {
    border-radius: 12px 12px 0 0;
    box-shadow: unset;
    margin-bottom: 0px !important;
    margin: 0;
    padding: 0 10px;
  }

  .Card-plan-main-container-margin-top {
    margin-top: 0;
  }

  .No-mobile {
    display: none;
  }

  .No-mobile {
    display: none;
  }

  .No-desktop {
    display: flex;
    width: 100%;
  }

  .Checkbox-container {
    margin-bottom: 0 !important;
    width: 100%;
  }

  .Quoting-header-my-quote {
    display: flex;
  }

  .Card-plan-footer-email-your-quote-button {
    padding: 0 0 0 4px;
  }
}
