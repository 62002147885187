.Round-button {
  align-items: center;
  background-color: transparent;
  border-radius: 36px;
  border: 2px solid var(--deep-blue-main);
  color: var(--deep-blue-main);
  cursor: pointer;
  display: flex;
  font-family: "IvyEpic-Bold";
  font-size: 16px;
  justify-content: center;
  min-width: 136px;
  padding: 18.5px 10px;
}

.Round-button > span {
  transform: translateY(2px);
}

.Round-button-primary {
  background-color: var(--deep-blue-main);
  color: var(--solid-white);
}
