.Payment-method-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 48px;
  width: 100%;
}

.Payment-method-title {
  align-self: flex-start;
  color: var(--main-text);
  display: flex;
  font-family: 'IvyPresto-Regular';
  font-size: 36px;
  letter-spacing: -0.4px;
  line-height: 46px;
  margin-bottom: 24px;
  text-align: left;
}

.Payment-method-error-label {
  color: var(--error);
  font-family: 'Inter-Medium';
  font-size: 11px;
  margin-top: 4px;
}

.Payment-method-section > fieldset {
  border: none;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Payment-method-section {
    margin-top: 16px;
  }
}


/* Mobile */
@media (max-width: 568px) {
  .Payment-method-section {
    margin: 0;
    justify-content: center;
  }

  .Payment-method-title {
    font-size: 24px;
  }
}
