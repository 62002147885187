.Pet-quote-drop-down-main-container {
  align-items: center;
  background-color: #182f43;
  border-radius: 8px;
  display: flex;
  height: 44px;
  justify-content: center;
  padding: 8px 0 0;
  pointer-events: all;
  position: relative;
  width: 448px;
}

.Pet-quote-drop-down-item-combo {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.Pet-quote-drop-down-item-container {
  align-items: center;
  background-color: var(--citron-main);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: auto;
  line-height: 1;
  margin: 2px 9px 8px 8px;
  min-height: 44px;
  outline-color: #1ccfca;
  padding: 0 4px;
  width: 420px;
  z-index: 2;
}

.Pet-quote-drop-down-item-up {
  display: flex;
  height: 44px;
  width: 100%;
}

.Pet-quote-drop-down-item {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  line-height: 1;
  width: 448px;
}


.Pet-quote-drop-down-item-container:hover {
  background-color: var(--citron-shade-1);
}

.Pet-quote-drop-down-arrow-container {
  align-items: center;
  border-radius: 40px;
  display: flex;
  height: 20px;
  justify-content: center;
  margin-left: 12px;
  min-width: 20px;
  pointer-events: none;
  transition: all 0.5s ease;
  width: 20px;
}

.Pet-quote-drop-down-arrow {
  height: 100%;
  pointer-events: none;
  transform: rotate(180deg);
  transition: all 0.3s ease;
  width: 100%;
}

.Pet-quote-drop-down-arrow-open {
  transform: rotate(360deg);
}

.Pet-quote-drop-down-plus-icon {
  height: 100%;
  width: 100%;
}

.Pet-quote-drop-down-pet-placeholder {
  height: 19px;
  width: 13px;
}

.Pet-quote-drop-down-pet-name {
  color: var(--deep-blue-main);
  font-family: 'Inter-Medium';
  font-size: 14px;
  height: 15px;
  line-height: 1;
  margin-left: 8px;
  overflow: hidden;
  pointer-events: none;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  min-width: 70%;
}

.Pet-quote-drop-down-pet-price {
  color: var(--deep-blue-main);
  font-family: 'Inter-Bold';
  font-size: 14px;
  margin-right: 12px;
  pointer-events: none;
}

.Pet-quote-drop-down-remove-pet {
  align-items: center;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  color: var(--deep-blue-shade-2);
  cursor: pointer;
  display: flex;
  flex: 0.2;
  font-size: 10px;
  height: 100%;
  justify-content: flex-end;
  padding-right: 16px;
}

.Pet-quote-drop-down-remove-pet:active, .Pet-quote-drop-down-remove-pet:hover{
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.Pet-quote-drop-down-child-list-container {
  -moz-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  align-items: center;
  background-color: var(--deep-blue-main);
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.50);
  color: var(--ivory-shade-3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: absolute;
  top: 55px;
  transition: all 0.2s ease;
  width: 100%;
}

.Pet-quote-drop-down-child-list-container-gone {
  opacity: 0;
  pointer-events: none;
  top: -20px;
}

.Pet-quote-drop-down-no-events {
  pointer-events: none;
}

.Pet-quote-drop-down-overlayed-container {
  align-items: center;
  background-color: transparent;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  outline: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

.Pet-quote-dropdown-item-to-remove,
.Pet-quote-dropdown-item-to-remove:hover {
  background-color: transparent;
  outline-color: transparent;
}

.Pet-quote-dropdown-item-to-remove > .Pet-quote-drop-down-pet-name {
  margin-right: 24px;
  width: auto;
}

.Pet-quote-dropdown-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 12px;
  width: 100%;
}

.Pet-quote-dropdown-button {
  align-items: center;
  background-color: var(--deep-blue-main);
  border-radius: 8px;
  color: var(--citron-main);
  cursor: pointer;
  display: flex;
  flex: 1;
  font-family: 'Inter-Regular';
  font-size: 12px;
  height: 40px;
  justify-content: center;
  margin: 0 4px;
}

.Pet-quote-drop-down-no-button {
  flex: 1;
}

.Pet-quote-drop-down-cancel-action {
  font-family: 'Inter-Bold';
  font-size: 14px;
}

.Pet-quote-drop-down-icon {
  align-items: center;
  display: flex;
  font-size: 14px;
  text-align: center;
}

.Pet-quote-drop-down-icon svg {
  padding-right: 6px;
}

.Pet-quote-drop-down-item-get-discount-label {
  margin-left: 60%;
  font-size: 11px;
}

.Pet-quote-drop-down-pet-name-full-width {
  width: 100% !important;
}

/* Mobile */
@media (max-width: 568px) {
  .Pet-quote-drop-down-main-container {
    align-items: flex-start;
    height: 44px;
    justify-content: flex-start;
    padding: 0;
    width: 89.165%;
  }

  .Pet-quote-drop-down-item-container {
    width: 96%;
    margin: 5px 7px !important;
    padding: 0;
  }

  .Pet-quote-drop-down-item {
    flex: 1;
    width: 100%;
  }

  .Pet-quote-drop-down-item-get-discount-label {
    margin-left: 35%;
  }

  .Pet-quote-drop-down-pet-name {
    width: 120px;
  }
}
