.Pet-selector-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
}

.Pet-selector-item {
  max-width: 448px;
  padding: 0 8px;
  padding: 4px;
  position: relative;
  width: 100%;
}

.Pet-selector-item-pet {
  background: var(--ivory-40);
  border-radius: 6px;
  display: flex;
  flex-direction: row;
}

.Pet-selector-item-pet-selected {
  background: var(--mint-80);
}

.Pet-selector-item-pet[data-error=true] {
  box-shadow: inset 0px 0px 0px 2px var(--danger-100);
}

.Pet-selector-item-pet[aria-expanded=true] {
  border-radius: 6px 6px 0 0;
}

.Pet-selector-item-pet[data-error=true].Pet-selector-item-pet-selected {
  background: var(--poppy-40);
}

.Pet-selector-item-pet-info {
  align-items: center;
  background: transparent;
  color: var(--deep-blue-base-100);
  cursor: pointer;
  display: flex;
  flex: 1;
  font-family: "IvyEpic-Bold";
  font-size: 14px;
  justify-content: space-between;
  line-height: 18px;
  padding: 13px 0 13px 24px;
}

.Pet-selector-item-pet-info[aria-selected=false],
.Pet-selector-item-pet[data-error=true] .Pet-selector-item-pet-info {
  padding-right: 12px !important;
}

.Pet-selector-item-pet-info-name {
  display: block;
  max-width: 100px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.Pet-selector-item-pet-info-name>img {
  margin-right: 4px;
  vertical-align: middle;
}

.Pet-selector-item-pencil-button {
  background: transparent;
  cursor: pointer;
  padding: 12px 20px 12px 24px;
}

.Pet-selector-item-pet-pencil {
  cursor: pointer;
}

.Pet-selector-item-menu {
  background: var(--deep-blue-base-100);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  margin: 0;
  padding: 10px 8px 10px;
  position: absolute;
  width: calc(100% - 16px);
  z-index: 1;
}

.Pet-selector-item-menu-item {
  background: var(--ivory-40);
  border-radius: 6px;
  color: var(--deep-blue-base-100);
  color: var(--deep-blue-base-100);
  font-family: "IvyEpic-Regular";
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  padding: 13px 20px;
  text-align: center;
}

.Pet-selector-item-menu-item:hover,
.Pet-selector-item-menu-item-hover {
  background: var(--mint-40);
}

.Pet-selector-item-menu-overlay {
  align-items: center;
  background-color: transparent;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  outline: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

.Pet-selector-item:not(:only-child) {
  max-width: 220px;
}

.Pet-selector-item:not(:only-child) .Pet-selector-item-pet-info {
  padding: 13px 0 13px 12px;
}

.Pet-selector-item:only-child .Pet-selector-item-pet-info {
  font-size: 18px;
}

.Pet-selector-item:only-child .Pet-selector-item-pet-info-name {
  max-width: 290px;
}

.Pet-selector-container:has(> :nth-child(3)) {
  padding: 0 16px;
  justify-content: flex-start;
}

.Pet-selector-wrapper {
  width: 100%;
}

.Pet-selector-dots {
  display: none;
}

.Pet-selector-arrow {
  align-items: center;
  background-color: transparent;
  display: flex;
  padding: 0;
}

.Pet-selector-arrow-switched > img {
  transform: rotate(180deg);
}

/* Mobile */
@media (max-width: 568px) {
  .Pet-selector-container {
    flex-wrap: nowrap;
    margin-top: 24px;
    overflow-x: auto;
    padding: 0;
    scroll-behavior: smooth;
  }

  .Pet-selector-container:has(> :nth-child(3)) {
    padding: 0;
  }

  .Pet-selector-item:not(:only-child) .Pet-selector-item-pet {
    width: 168px;
  }

  .Pet-selector-item:only-child .Pet-selector-item-pet-info-name {
    max-width: 250px;
  }

  .Pet-selector-item:not(:only-child) .Pet-selector-item-pet-selected .Pet-selector-item-pet-info {
    max-width: 130px;
  }

  .Pet-selector-wrapper {
    position: relative;
    width: 90%;
  }

  .Pet-selector-item {
    position: static;
  }

  .Pet-selector-item-menu {
    max-width: 100%;
    position: absolute;
    top: 76px;
  }

  .Pet-selector-item:not(:only-child) .Pet-selector-item-menu {
    left: -3px;
    max-width: 80%;
    width: 168px;
  }

  .Pet-selector-item-pencil-button {
    padding: 12px;
  }

  .Pet-selector-dots,
  .Pet-selector-dots-container {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: center;
  }

  .Pet-selector-dots-container {
    margin-top: 0;
  }

  .Pet-selector-dot {
    background: var(--ivory-40);
    border-radius: 50%;
    border: 2px solid var(--ivory-40);
    height: 8px;
    width: 8px;
  }

  .Pet-selector-dot[data-selected=true] {
    background: var(--deep-blue-base-100);
    border: 2px solid var(--ivory-40) !important;
  }

  .Pet-selector-dot[data-error=true] {
    background: var(--danger-100);
    border: 2px solid var(--danger-100);
  }
}
