.Landing-welcome {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.Landing-welcome-image-container {
  height: 80vh;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.Landing-welcome-background-big {
  background-image: url(../../../assets/landing/family-gray.jpg);
  background-position-y: -415px;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.Landing-welcome-content {
  left: 115px;
  position: absolute;
  top: 67px;
  width: 39%;
}

.Landing-welcome-company {
  color: var(--ivory-main);
  font-family: 'IvyPresto-Regular';
  font-size: 72px;
  letter-spacing: -1.07px;
  line-height: 86px;
  margin: 0;
  text-align: center;
}

.Landing-welcome-text {
  color: var(--ivory-main);
  font-family: 'IvyEpic-Regular';
  font-size: 18px;
  line-height: 1.6;
  margin: 0;
  max-width: 320px;
  text-align: center;
}

.Landing-welcome-offer {
  color: var(--ivory-main);
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  margin: 24.29px 0;
  max-width: 274px;
}

.Landing-welcome-offer:nth-child(2) {
  font-size: 14px;
}

.Landing-welcome-button {
  background-color: var(--blue-main);
  color: var(--main-text);
  height: 50px;
  width: 150px;
}

.Landing-welcome-offer span:first-child {
  font-family: 'IvyEpic-Bold';
}

.Landing-welcome-overlay {
  display: none;
}

/* Desktop only */
@media (min-width: 1201px) {
  .Landing-welcome-image-container {
    min-height: 601px;
  }

  .Landing-welcome-company {
    text-align: left;
  }

  .Landing-welcome-text {
    margin-top: 24px;
    text-align: left;
    width: 444px;
  }
}

/* Tablet - Big */
@media (max-width: 1200px) and (min-width: 569px) {
  .Landing-welcome-image-container {
    height: 80.7vh;
  }

  .Landing-welcome-background-big {
    background-position: center;
  }

  .Landing-welcome-content {
    left: 67px;
    position: absolute;
    top: 60px;
    width: 73.34%;
  }

  .Landing-welcome-company {
    font-size: 50px;
    line-height: 1.083;
    margin-top: 30px;
    text-align: left;
  }

  .Landing-welcome-text {
    font-size: 18px;
    line-height: 1.556;
    margin: 16px 0;
    text-align: left;
    width: 70%;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Landing-welcome-background-big {
    background-position-x: 55%;
    background-position-y: 55%;
    background-size: auto 150%;
  }

  .Landing-welcome-overlay {
    background-color: #01313d40;
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .Landing-welcome-content {
    left: 15px;
    right: 15px;
    top: 60px;
    width: auto;
  }

  .Landing-welcome-company {
    font-size: 36px;
    line-height: 1.167;
    margin: 24px 5px 0;
  }

  .Landing-welcome-text {
    font-size: 16px;
    line-height: 1.5;
    margin: 24px 25px;
  }

  .Landing-welcome-button {
    margin: 0 auto;
    width: 81%;
  }

  .Landing-welcome-offer {
    margin: 17px auto 20px;
    text-align: center;
  }

  .Landing-welcome-get-quote {
    text-align: center;
  }

  .Landing-welcome-offer span:first-child {
    margin-bottom: 6px;
  }
}
