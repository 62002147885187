.Terms-and-condition-checkbox-container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 36px;
  justify-content: flex-start;
  width: 450px;
}

.Terms-and-condition-checkbox-label {
  align-items: center;
  color: #3d484d;
  cursor: pointer;
  display: flex;
  font-family: 'Inter-Medium';
  font-size: 13px;
  padding-bottom: 0px !important;
  pointer-events: none;
}

.Terms-and-condition-checkbox-input {
  top: 0px !important;
}

.Terms-and-condition-checkbox-content {
  align-self: center;
  display: flex;
  height: auto;
  width: 100%;
}

.Terms-and-condition-error-label {
  bottom: 3px;
  color: var(--error);
  font-family: 'Inter-Medium';
  font-size: 11px;
  padding-left: 33px;
  pointer-events: none;
  position: relative;
}

.Terms-and-condition-child-modal-clickable {
  background-color: transparent;
  color: var(--main-text);
  cursor: pointer;
  font-family: 'IvyEpic-Regular';
  font-size: 12px;
  padding: 0;
  pointer-events: all;
  text-decoration: underline;
}

.Terms-and-condition-checkbox-container {
  width: 100%;
}

.Terms-and-condition-error-label {
  bottom: 15px;
  padding: 0 !important;
  position: absolute;
  bottom: 4px;
  left: 0;
}
