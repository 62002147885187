.what-figo-covers-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 4%;
  width: 100%;
}

.what-figo-covers-box {
  width: 72%;
}

.what-figo-covers-box > ul {
  margin: auto;
  padding: 0;
  width: 100%;
}

.what-figo-covers-title {
  color: var(--deep-blue-main);
  display: flex;
  font-family: 'IvyPresto-Regular';
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  justify-content: flex-start;
  letter-spacing: -0.4px;
  line-height: 46px;
  margin: 0;
  text-align: center;
  width: 72%;
}

.what-figo-covers-subtitle-description {
  color: var(--deep-blue-shade-2);
  display: flex;
  font-family: 'IvyEpic-Regular';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 8px 0 24px 0;
  text-align: center;
}

.what-figo-covers-item {
  align-items: center;
  border-bottom: 1px solid var(--deep-blue-shade-3);
  flex-direction: column;
  padding: 2px;
}

.what-figo-covers-item button {
  align-items: center;
  cursor: pointer;
}

.what-figo-covers-item-title {
  align-items: center;
  color: var(--deep-blue-main);
  display: flex;
  font-family: 'IvyEpic-Bold';
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 21px;
}

.what-figo-covers-item button:hover {
  color: var(--deep-blue-main);
}

.what-figo-covers-item-order {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.what-figo-covers-item-order-opened{
  display: flex;
  height: 0px;
  justify-content: space-between;
  padding: 25px 0 12px 0;
  width: 100%;
}

.what-figo-covers-boxitem {
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  gap: 17px;
  outline: none;
  transition: none;
}

ul {
  list-style-type: none;
}

.what-figo-covers-boxitem:hover {
  background-color: transparent !important;
}

.what-figo-covers-item button:hover,
.what-figo-covers-item input[type="button"]:hover {
  background-color: inherit;
  border-color: inherit;
  color: inherit;
  opacity: 1;
  text-decoration: none;
}

.what-figo-covers-text {
  background-color: transparent;
  border: none;
  color: var(--deep-blue-shade-2);
  cursor: text;
  font-family: 'IvyEpic-Regular';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 5px;
  text-align: justify;
}

.what-figo-covers-text:hover {
  color: var(--deep-blue-shade-2);
}

.what-figo-covers-icon {
  height: 32px;
  width: 32px;
}

.what-figo-covers-plus-less-icon {
  height: 16px;
  margin-top: 0px !important;
  width: 16px;
}

.what-figo-covers-accordion-content {
  padding: 0 20px 0 45px;
}

.what-figo-covers-accordion-content-hidden {
  display: none;
}

/* Tablet - Medium */
@media (min-width: 620px) {
  .what-figo-covers-plus-less-icon {
    margin-top: 10px;
  }
}

/* Mobile */
@media (max-width: 570px) {
  .what-figo-covers-box {
    width: 90%;
  }

  .what-figo-covers-title {
    font-size: 24px;
    width: 100%;
  }

  .what-figo-covers-item-title {
    margin-right: 10px;
    text-align: left;
    white-space: normal;
  }

  .what-figo-covers-text {
    text-align: left;
  }

  .what-figo-covers-plus-less-icon {
    margin-top: 5px;
  }

  .what-figo-covers-box > ul {
    width: 100%;
  }
}
