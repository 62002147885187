@font-face {
  font-family: "Inter-Regular";
  src: url("assets/fonts/Inter-Regular.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Inter-Regular.otf") format("opentype"), url("assets/fonts/Inter-Regular.woff") format("woff"),
    url("assets/fonts/Inter-Regular.ttf") format("truetype"),
    url("assets/fonts/Inter-Regular.svg#Inter-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter-SemiBold";
  src: url("assets/fonts/Inter-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Inter-SemiBold.otf") format("opentype"), url("assets/fonts/Inter-SemiBold.woff") format("woff"),
    url("assets/fonts/Inter-SemiBold.ttf") format("truetype"),
    url("assets/fonts/Inter-SemiBold.svg#Inter-SemiBold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter-Bold";
  src: url("assets/fonts/Inter-Bold.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Inter-Bold.otf") format("opentype"), url("assets/fonts/Inter-Bold.woff") format("woff"),
    url("assets/fonts/Inter-Bold.ttf") format("truetype"), url("assets/fonts/Inter-Bold.svg#Inter-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter-Black";
  src: url("assets/fonts/Inter-Black.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Inter-Black.otf") format("opentype"), url("assets/fonts/Inter-Black.woff") format("woff"),
    url("assets/fonts/Inter-Black.ttf") format("truetype"),
    url("assets/fonts/Inter-Black.svg#Inter-Black") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter-Medium";
  src: url("assets/fonts/Inter-Medium.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Inter-Medium.otf") format("opentype"), url("assets/fonts/Inter-Medium.woff") format("woff"),
    url("assets/fonts/Inter-Medium.ttf") format("truetype"),
    url("assets/fonts/Inter-Medium.svg#Inter-Medium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter-Thin';
  src: url('assets/fonts/Inter-Thin.eot?#iefix') format('embedded-opentype'), 
    url('assets/fonts/Inter-Thin.otf')  format('opentype'),
    url('assets/fonts/Inter-Thin.woff') format('woff'),
    url('assets/fonts/Inter-Thin.ttf')  format('truetype'),
    url('assets/fonts/Inter-Thin.svg#Inter-Thin') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter-Light';
  src: url('assets/fonts/Inter-Light.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Inter-Light.otf')  format('opentype'),
    url('assets/fonts/Inter-Light.woff') format('woff'),
    url('assets/fonts/Inter-Light.ttf')  format('truetype'),
    url('assets/fonts/Inter-Light.svg#Inter-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DMSerifDisplay-Italic';
  src: url('assets/fonts/DMSerifDisplay-Italic.eot?#iefix') format('embedded-opentype'),  
    url('assets/fonts/DMSerifDisplay-Italic.woff') format('woff'), 
    url('assets/fonts/DMSerifDisplay-Italic.ttf')  format('truetype'), 
    url('assets/fonts/DMSerifDisplay-Italic.svg#DMSerifDisplay-Italic') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'DMSerifDisplay-Regular';
  src: url('assets/fonts/DMSerifDisplay-Regular.eot?#iefix') format('embedded-opentype'),  
    url('assets/fonts/DMSerifDisplay-Regular.woff') format('woff'), 
    url('assets/fonts/DMSerifDisplay-Regular.ttf')  format('truetype'), 
    url('assets/fonts/DMSerifDisplay-Regular.svg#DMSerifDisplay-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IvyPresto-Regular";
  src: url("assets/fonts/IvyPresto-Regular.otf") format("opentype"),
  url("assets/fonts/IvyPresto-Regular.svg") format("svg"),
  url('assets/fonts/IvyPresto-Regular.ttf')  format('truetype'),
  url('assets/fonts/IvyPresto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IvyPresto-Italic";
  src: url("assets/fonts/IvyPresto-Italic.otf") format("opentype"),
  url("assets/fonts/IvyPresto-Italic.svg") format("svg"),
  url('assets/fonts/IvyPresto-Italic.ttf')  format('truetype'),
  url('assets/fonts/IvyPresto-Italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IvyEpic-Bold";
  src: url("assets/fonts/IvyEpic-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IvyEpic-Bold-Italic";
  src: url("assets/fonts/IvyEpic-Bold-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IvyEpic-Italic";
  src: url("assets/fonts/IvyEpic-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IvyEpic-Regular";
  src: url("assets/fonts/IvyEpic-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-Regular";
  src: url("assets/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-Semibold";
  src: url("assets/fonts/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-Bold";
  src: url("assets/fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: #f8f8f8;
  margin: 0;
  font-family: 'Inter-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button:focus {
  outline-color: #2fb3b4;
  border: none;
}

button:hover {
  opacity: 0.8;
}

button {
  border: none;
}

input {
  caret-color: #2fb3b4;
}

input[type="button"]{
  border: none;
  outline-color: #2fb3b4;
}

input[type="button"]:focus{
  border: none;
  outline-color: #2fb3b4;
}

input[type="button"]:hover {
  opacity: 0.8;
}

label, input[type="radio"] {
  cursor: pointer;
  outline-color: #2fb3b4;
}

h1, h2, h3 {
  font-weight: normal;
}

.Like-span {
  background: transparent;
  border: none;
  padding: 0;
  text-decoration: none;
}

:root {
  --blue-100: #f0f2ff;
  --blue-400: #001184;
  --blue-main: #95e6e2;
  --blue-shade-1: #a9e5e2;
  --blue-shade-2: #bdece9;
  --blue-shade-3: #d3f2f1;
  --blue-shade-dark: #3ecbc4;
  --citron-main: #e6f972;
  --citron-shade-1: #dcf34b;
  --citron-shade-2: #ebf0a1;
  --citron-shade-3: #f0f4bd;
  --citron-40: #f6ffc7;
  --citron-60: #f1feab;
  --citron-80: #ebfe8f;
  --citron-base-100: #e6fe73;
  --citron-120: #d2eb38;
  --cotton-100: #f9fafa;
  --cotton-200: #f6f8f8;
  --cotton-300: #eceeef;
  --cotton-400: #d3d5d9;
  --cotton-500: #b5bac0;
  --danger-40: #fcebeb;
  --danger-100: #d31313;
  --danger-120: #a10707;
  --darkest-grey: #3d484d;
  --deep-blue-15: #d9e0e2;
  --deep-blue-40: #99adb1;
  --deep-blue-60: #67838b;
  --deep-blue-80: #3373a7;
  --deep-blue-base-100: #01313d;
  --deep-blue-120: #002028;
  --deep-blue-main: #01313d;
  --deep-blue-shade-1: #293742;
  --deep-blue-shade-2: #4a545d;
  --deep-blue-shade-3: #737a83;
  --evening-blue-100: #d0e4f4;
  --green-100: #ecfdf3;
  --hydrant-200: #fed1cd;
  --hydrant-400: #c12010;
  --ivory-20: #fefcf9;
  --ivory-40: #fefaf6;
  --ivory-60: #fdf7f1;
  --ivory-80: #fdf5ed;
  --ivory-base-100: #fcf2e8;
  --ivory-120: #eedac3;
  --ivory-main: #faf2e9;
  --ivory-shade-1: #f9f1e8;
  --ivory-shade-2: #faf4ed;
  --ivory-shade-3: #fbf6f1;
  --midnight-100: #8d939a;
  --midnight-200: #60666c;
  --midnight-base-300: #43474b;
  --midnight-400: #202327;
  --midnight-base-500: #040506;
  --mint-40: #d5f5f3;
  --mint-60: #bff0ed;
  --mint-80: #aaebe8;
  --mint-base-100: #95e6e2;
  --mint-120: #70d4cf;
  --pc-color-onyx-500: #040206;
  --poppy-40: #f3b9b2;
  --poppy-60: #f69e94;
  --poppy-80: #f37e71;
  --poppy-base-100: #f05e4d;
  --poppy-main: #e06855;
  --poppy-shade-1: #df7159;
  --poppy-shade-2: #e68e76;
  --poppy-shade-3: #eeb09a;
  --red-100: #fff1f0;
  --solid-black: #000;
  --solid-white: #fff;
  --success-tint: #f1f7e3;
  --success: #527205;

  --header-background: var(--deep-blue-main);
  --hinted-text: #6b7078;
  --contrast-dark: var(--ivory-shade-3);
  --main-text: var(--deep-blue-main);
  --error: #cc0000;
  --gray: #d2d8df;
  --gray-border: #b0bbbf;
}
