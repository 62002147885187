.Billing-cycle-title  {
  align-items: flex-start;
  color: var(--main-text);
  display: flex;
  font-family: 'IvyPresto-Regular';
  font-size: 36px;
  margin: 0 0 24px 0;
  text-align: left;
  width: 100%;
}

.Billing-cycle-section {
  gap: 16px;
  margin-bottom: 40px;
  width: 736px;
}

.Billing-cycle-div:first-child {
  margin: 0 22px 0 64px;
}

.Billing-cycle-div:last-child {
  margin-right: 64px;
}

.Billing-cycle-section .Billing-cycle-div:first-child {
  margin: 0;
}

.Billing-cycle-section .Billing-cycle-div:last-child {
  margin-right: 0;
}

.Billing-cycle-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.Billing-cycle-column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.Billing-cycle-div {
  background-color: #ffffffbf;
  border-radius: 8px;
  border: 1px solid var(--deep-blue-shade-3);
  outline-color: #2fb3b4;
  padding: 16px;
}

.Billing-cycle-div:focus {
  border: 1px solid #d9dee1;
}

.Billing-cycle-div:hover {
  cursor: pointer;
}

.Billing-cycle-div div {
  pointer-events: none;
}

.Billing-cycle-div[data-checked=true] {
  background-color: var(--blue-shade-3);
  border-color: var(--deep-blue-main);
}

.Billing-cycle-plan-container {  
  font-family: 'IvyEpic-Bold';
  width: 100%;
}

.Billing-cycle-plan-title {
  align-items: flex-end;
  color: var(--deep-blue-shade-2);
  font-size: 16px;
  justify-content: space-between;
  margin: 0 0 10px 0;
  text-align: center;
  width: auto;
}

.Billing-cycle-plan-container div label {
  margin: 0;
}

.Billing-cycle-price {
  color: var(--main-text);
  justify-content: flex-start;
}

.Billing-cycle-price-mobile {
  display: none;
}

.Billing-cycle-price-currency-cent {
  font-size: 14px;
}

.Billing-cycle-price-dollar {
  font-size: 24px;
  margin: -2px 0 0 2px;
}

.Billing-cycle-price-tag {
  align-self: flex-end;
  color: #22435e;
  display: flex;
  font-size: 12px;
  margin: 0 0 4px 3px;
}

.Billing-cycle-additional-fee {
  align-items: flex-end;
  margin-top: 40px;
  justify-content: flex-start;
}

.Billing-cycle-additional-fee span {
  color: var(--deep-blue-shade-2);
  font-size: 12px;
  text-align: left;
  width: 75%;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Billing-cycle-div {
    max-width: unset;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Billing-cycle-title  {
    font-size: 24px;
    margin: 0;
    text-align: center;
  }

  .Billing-cycle-row {
    align-items: center;
    flex-direction: column;
  }

  .Billing-cycle-div,
  .Billing-cycle-div:first-child {
    height: 86px;
    margin: 24px 0 16px 0px;
    max-width: 87%;
    min-height: 86px;
    padding: 12px 11px 8px;
    width: 87%;
  }

  .Billing-cycle-div:last-child {
    margin: 0;
  }

  .Billing-cycle-section {
    margin: 16px 0;
    gap: 16px;
    width: 100%;
  }

  .Billing-cycle-section .Billing-cycle-div:first-child,
  .Billing-cycle-section .Billing-cycle-div:last-child{
    max-width: 100%;
    width: 100%;
  }

  .Billing-cycle-plan-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    margin: 0;
  }

  .Billing-cycle-row .Billing-cycle-plan-title {
    flex-direction: row;
    margin-bottom: 6px;
  }

  .Billing-cycle-price {
    flex-direction: row;
    width: fit-content;
  }

  .Billing-cycle-plan-container > .Billing-cycle-price {
    display: none;
  }

  .Billing-cycle-price-mobile,
  .Billing-cycle-fee-container {
    align-items: flex-end;
    display: flex;
    margin-right: 10px;
    width: fit-content;
  }

  .Billing-cycle-price-tag {
    font-family: 'IvyEpic-Bold';
    margin-bottom: 3px;
    width: max-content;
  }

  .Billing-cycle-additional-fee {
    align-items: flex-start;
    height: 60%;
  }

  .Billing-cycle-div[data-checked=true] {
    border-width: 2px;
  }
}
