.Text-alerts-section>h3 {
  color: #01313d;
  font-family: 'IvyPresto-Regular';
  font-size: 24px;
  line-height: 1.6;
  letter-spacing: -0.4px;
  margin: 0;
}

.Text-alerts-section input {
  background-color: #fbf6f1;
  width: 100%;
}

.Text-alerts-section-desc {
  color: #293742;
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
}

.Text-alert-toggle-label {
  font-size: 11px;
  margin-left: 22px;
}

.Text-alerts-phone-label {
  padding-left: 8px;
}

.Text-alert-toggle {
  margin-top: 24px;
}

.Text-alerts-section-submit-section {
  margin-top: 24px;
}

.Text-alerts-section-submit {
  background-color: #22435e;
  border-radius: 5px;
  color: #fff;
  font-family: 'Inter-Regular';
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  width: 118px;
}

.Text-alerts-section-submit[aria-disabled=true] {
  background-color: #b0bbbf;
}

.Text-alert-success {
  background-color: #e0e7eb;
  border-radius: 5px;
  padding: 10px 16px;
}

.Text-alert-success>img {
  margin-right: 8px;
}

.Text-alert-success>span {
  color: #182f43;
  font-family: 'Helvetica';
}

.Text-alert-error {
  color: #cc0000;
  font-family: 'Inter-Medium';
  font-size: 10px;
}

.Text-alert-terms-button {
  background-color: transparent;
  color: #293742;
  cursor: pointer;
  font-family: 'IvyEpic-Regular';
  font-size: 10px;
  font-weight: 700;
  padding: 0;
  text-decoration: underline;
}

.Input-whit-error-label {
  font-weight: 700;
}

/* Mobile */
@media (max-width: 568px) {
  .Text-alerts-section {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .Text-alerts-section>h3 {
    text-align: center;
    width: 75%;
  }

  .Text-alerts-section-desc {
    margin: 24px 0 32px;
    text-align: center;
  }

  .Text-alerts-section-submit {
    margin: auto;
  }

  .Text-alerts-phone {
    width: 100%;
  }
}
