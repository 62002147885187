.Powerup-see-whats-covered-link-main-container {
  background-color: transparent;
  cursor: pointer;
  margin-top: 18px;
}

.Powerup-see-whats-covered-link-main-container-plans {
  background-color: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 72%;
}

.Powerup-see-whats-covered-link {
  color: var(--deep-blue-main);
  cursor: pointer;
  font-family: 'IvyEpic-Bold';
  font-size: 12px;
  margin: 2px 0 0;
  pointer-events: none;
  text-decoration: underline;
}

/* Tablet - Medium */
@media (max-width: 710px) {
  .Powerup-see-whats-covered-link-main-container-plans {
    width: 96%;
  }
}
