.Landing-footer-container {
  background-color: #002028;
  padding: 0 160px 0 160px;
}

.Landing-footer {
  color: var(--ivory-main);
  font-family: 'IvyEpic-Regular';
  width: 100%;
}

.Landing-footer-app-logo-container {
  display: flex;
  justify-content: center;
}

.Landing-footer-app-logo-text {
  font-size: 14px;
  max-width: 1119px;
  text-align: justify;
}

.Landing-footer-app-logo-text>a {
  color: var(--ivory-main);
  font-family: 'IvyEpic-Regular';
}

.Landing-footer-links-icons {
  height: 16px;
  padding-left: 24px;
  width: 16px;
}

.Landing-footer-copy-right span {
  color: var(--ivory-main);
  margin: 72.75px 0 0;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Landing-footer-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .Landing-footer {
    margin: 0;
    width: 93.8%;
  }

  .Landing-footer-app-logo-text {
    margin: 0 0 0 20px;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Landing-footer-app-logo-container {
    align-items: center;
    flex-direction: column;
  }

  .Landing-footer-app-logo-text {
    font-size: 12px;
    margin: 0;
    padding: 0;
    width: 98%;
  }

  .Landing-footer {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .Landing-footer-copy-right span {
    margin: 40px 0 0;
    width: 280px;
  }
}
