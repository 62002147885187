.Toggle {
  display: inline-block;
  height: 16.53px;
  margin-right: 13px;
  position: relative;
  width: 32.5px;
}

.Toggle-wrapper {
  display: flex;
  flex-direction: row;
}

.Toggle-input {
  border-radius: 40px;
  cursor: pointer;
  height: 75%;
  opacity: 0;
  width: 90%;
}

.Toggle-slidebar {
  background: #d9dee1;
  border-radius: 40px;
  bottom: 1.1px;
  height: 14.3px;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 1.1px;
  width: 32.5px;
}

.Toggle-slider {
  background-color: transparent;
  border-radius: 40px;
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
}

.Toggle-slider::before {
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  -webkit-transition: 0.4s;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5);
  content: "";
  height: 16.53px;
  position: absolute;
  transition: 0.4s;
  width: 16.62px;
}

.Toggle-input:checked + .Toggle-slidebar {
  background: #01313d;
}

.Toggle-input:checked + .Toggle-slidebar + .Toggle-slider::before {
  -moz-transform: translateX(15.88px);
  -o-transform: translateX(15.88px);
  -webkit-transform: translateX(15.88px);
  transform: translateX(15.88px);
}

.Toggle-content {
  margin: 0 0 0 11.95px;
}

.Toggle-content > b {
  font-family: 'Inter-Semibold';
}

.Toggle-content > a {
  color: #22435e;
  font-family: 'Inter-Medium';
  text-decoration: underline;
}
