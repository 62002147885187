.Modal-forget-password-container {
  background-color: var(--ivory-shade-3);
  border-radius: 12px;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 381px;
}

.Modal-forget-password-title {
  color: var(--deep-blue-main);
  font-family: 'Inter-SemiBold';
  font-size: 22px;
  font-weight: 600;
}

.Modal-forget-password-description {
  color: var(--deep-blue-shade-2);
  margin-top: 16px;
  text-align: center;
}

.Modal-forget-password-footer-container {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  width: 317px;
}

.Modal-forget-password-button-footer-reset {
  background-color: var(--deep-blue-main);
  border-radius: 8px;
  color: var(--ivory-main);
  cursor: pointer;
  height: 60px;
  width: 150px;
}
