.Powerup-card-container, .Powerup-card-container-selected {
  align-items: center;
  background-color: #ffffffbf;
  border-radius: 16px;
  border: 2px solid var(--deep-blue-shade-3);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-bottom: 24px;
}

.Powerup-card-container-selected {
  background-color: var(--blue-shade-3);
  border-color: var(--deep-blue-main);
  border-style: solid;
  border-width: 2px;
}

.Powerup-card-body-content {
  padding: 24.8px 24px 24.5px 32px;
}

.Powerup-card-left-content {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 478px;
}

.Powerup-card-title {
  align-items: center;
  color: #22435e;
  display: flex;
  flex: 1;
  font-family: 'Inter-SemiBold';
  font-size: 18px;
  height: 100%;
  justify-content: flex-start;
  line-height: 1.33;
  width: 100%;
}

.Powerup-card-title > h3 {
  color: #22435e;
  font-family: 'Inter-SemiBold';
  font-size: 18px;
  line-height: 1.33;
  margin: 0;
}

.Powerup-card-plan-info-image {
  cursor: pointer;
  height: 16px;
  margin: 0 8px 0 0;
  width: 16px;
}

.Powerup-card-content {
  align-items: flex-start;
  color: var(--deep-blue-shade-2);
  display: flex;
  flex-direction: column;
  font-family: 'IvyEpic-Regular';
  font-size: 12px;
  height: 100%;
  line-height: 18px;
  margin: 16.8px 0 0 0;
}

.Powerup-card-information {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 26px;
}


.Powerup-card-information > sup {
  line-height: 0;
}

.Powerup-title-container-optional + .Powerup-card-content {
  margin-top: 8px;
}

.Powerup-card-content > p {
  margin: 0;
  width: 80%;
}

.Powerup-whats-covered {
  align-items: center;
  align-self: flex-start;
  display: flex;
  margin-top: 24px;
}

.Powerup-card-price-text {
  color: var(--deep-blue-shade-2);
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  letter-spacing: -0.4375;
}

.Powerup-card-actions-radios,
.Powerup-card-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Powerup-card-actions-end {
  justify-content: flex-end;
}

.Powerup-card-actions-radios {
  flex-direction: column;
  gap: 24px;
  justify-content: flex-start;
}

.Powerup-card-option-wrapper,
.Powerup-card-option {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Powerup-card-option-wrapper {
  flex-direction: column;
}

.Powerup-card-container[data-error=true] {
  background-color: #cc00000f;
  border-color: var(--error);
}

.Powerup-card-error {
  color: var(--error);
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  height: 0;
}

.Powerup-card-container[data-error=true]  .Powerup-card-error {
  height: fit-content;
}

/* Mobile */
@media (max-width: 568px) {
  .Powerup-card-container, .Powerup-card-container-selected {
    width: 86% !important;
  }

  .Powerup-card-content {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
  }

  .Powerup-card-content > p {
    width: 100%;
  }

  .Powerup-card-container, .Powerup-card-container-selected {
    padding: 16px;
    width: 90%;
  }

  .Powerup-card-body-content {
    padding: 0;
  }

  .Powerup-card-price-text {
    clear: both;
    display: inline-block;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
  }
}
