.Header {
  background-color: var(--deep-blue-base-100);
}

.Header-content {
  display: flex;
  margin: auto;
  max-width: 1300px;
  padding: 44px 3% 44px;
  position: relative;
}

.Header-fixed {
  animation: slideInHeader 0.8s alternate;
  position: sticky;
  top: 0;
  z-index: 5;
}

/* Recover the pixels from scroll on the next principal container element */
.Header-fixed + div,
.Header-fixed + main {
  margin-top: 4px;
}

.Header-logo-image {
  height: 64px;
  width: 161px;
}

.Header-fixed>.Header-content {
  padding-bottom: 15px;
}

.Header-fixed .Header-underwriting {
  display: none;
}

.Header-fixed .Header-pet-selector-wrapper {
  margin-bottom: 0;
}

.Header-actions {
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 5;
}

.Header-pet-selector-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 32px calc(50% - 440px) 66px;
  max-width: 720px;
  width: 100%;
}

.Header-fixed .Header-pet-selector-wrapper-hide {
  display: none;
}

.Header-price {
  min-height: 120px;
}

.Header-fixed .Header-price {
  min-height: 0;
}

.Header-pet-selector-wrapper:only-child {
  margin-top: 0;
}

@keyframes slideInHeader {
  0% {
    top: -120px;
  }

  100% {
    top: 0;
  }
}

/* Tablet - Big */
@media (min-width: 568px) and (max-width: 1200px) {
  .Header {
    position: sticky;
    top: 0;
    z-index: 5;
  }

  .Header-fixed .Header-pet-selector-wrapper-hide {
    display: flex;
  }

  .Header-price {
    min-height: 0;
  }

  .Header-content {
    align-items: flex-start;
    flex-direction: column;
    padding: 24px 0 44px;
  }

  .Header-logo-image {
    height: 28px;
    margin-left: 16px;
    width: 70px;
  }

  .Header-pet-selector-wrapper {
    margin: auto;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Header {
    position: sticky;
    top: 0;
    z-index: 5;
  }

  .Header-fixed .Header-pet-selector-wrapper-hide {
    display: flex;
  }

  .Header-content {
    align-items: flex-start;
    flex-direction: column;
    padding: 24px 0 8px;
  }

  .Header-logo-image {
    height: 28px;
    margin-left: 16px;
    width: 70px;
  }

  .Header-pet-selector-wrapper {
    margin: 0;
  }

  .Header-price {
    min-height: 0;
  }
}
