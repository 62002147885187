.Feature {
  width: 308px;
}

.Feature h3 {
  color: var(--main-text);
  font-family: 'IvyEpic-Bold';
  font-size: 22px;
  line-height: 32px;
  margin: 21px auto 3px;
  text-align: left;
}

.Feature p {
  color: var(--main-text);
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  line-height: 21px;
  margin: 11px auto;
  text-align: left;
}

/* Tablet - Big */
@media (max-width: 1200px) and (min-width: 711px) {
  .Feature {
    width: 281px;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Feature {
    width: 233px;
  }

  .Feature h3 {
    width: 232px;
  }
}
