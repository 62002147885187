.Purchase-page-content-container {
  align-items: center;
  background-image: url('../../../assets/purchase/background.png');
  background-repeat: repeat;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  min-height: 100vh;
}

/* Safari 10+ */
@media not all and (max-height: 899px) and (min-resolution:.1dpi) {
  .Purchase-page-content-container {
    height: 100vh;
  }

}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Purchase-page-content-container {
    height: fit-content;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Purchase-page-content-container {
    background-size: unset;
    height: unset;
    padding: 16px;
  }

  .Purchase-result-contact {
    display: flex;
    justify-content: center;
  }
}
