.Purchase-loader {
  aspect-ratio: 2;
  --deep-blue-main: no-repeat radial-gradient(circle closest-side, #01313d 90%, #0000);
  --orange: no-repeat radial-gradient(circle closest-side, #ffa300 90%, #0000);
  --yellow: no-repeat radial-gradient(circle closest-side, #EECC38 90%, #0000);
  background:
    var(--deep-blue-main) 0% 50%,
    var(--deep-blue-main) 50% 50%,
    var(--deep-blue-main) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: loader-orange-animation 1s infinite linear;
  width: 20px;
}

.Purchase-loader[data-status=progress] {
  aspect-ratio: 2;
  background:
    var(--deep-blue-main) 0% 50%,
    var(--deep-blue-main) 50% 50%,
    var(--deep-blue-main) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: loader-yellow-animation 1s infinite linear;
  width: 20px;
}

@keyframes loader-orange-animation {
  20% {
    background:
      var(--orange) 0% 50%,
      var(--deep-blue-main) 50% 50%,
      var(--deep-blue-main) 100% 50%;
    background-size: calc(100%/3) 50%;
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }

  40% {
    background:
      var(--orange) 0% 50%,
      var(--orange) 50% 50%,
      var(--deep-blue-main) 100% 50%;
    background-size: calc(100%/3) 50%;
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }

  60% {
    background:
      var(--deep-blue-main) 0% 50%,
      var(--orange) 50% 50%,
      var(--orange) 100% 50%;
    background-size: calc(100%/3) 50%;
    background-position: 0% 50%, 50% 100%, 100% 0;
  }

  80% {
    background:
      var(--deep-blue-main) 0% 50%,
      var(--orange) 50% 50%,
      var(--orange) 100% 50%;
    background-size: calc(100%/3) 50%;
    background-position: 0% 50%, 50% 50%, 100% 100%
  }
}

@keyframes loader-yellow-animation {
  20% {
    background:
      var(--yellow) 0% 50%,
      var(--deep-blue-main) 50% 50%,
      var(--deep-blue-main) 100% 50%;
    background-size: calc(100%/3) 50%;
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }

  40% {
    background:
      var(--yellow) 0% 50%,
      var(--yellow) 50% 50%,
      var(--deep-blue-main) 100% 50%;
    background-size: calc(100%/3) 50%;
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }

  60% {
    background:
      var(--deep-blue-main) 0% 50%,
      var(--yellow) 50% 50%,
      var(--yellow) 100% 50%;
    background-size: calc(100%/3) 50%;
    background-position: 0% 50%, 50% 100%, 100% 0;
  }

  80% {
    background:
      var(--deep-blue-main) 0% 50%,
      var(--yellow) 50% 50%,
      var(--yellow) 100% 50%;
    background-size: calc(100%/3) 50%;
    background-position: 0% 50%, 50% 50%, 100% 100%
  }
}

.Purchase-Multi-Card-container {
  align-items: center;
  background-color: var(--ivory-shade-3);
  border-radius: 12px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.20);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 423px;
  overflow: auto;
  padding: 40px;
  position: relative;
  z-index: 2;
}

.Purchase-Multi-Modal-backdrop {
  background-color: rgba(0, 0, 0, 0.20);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.Purchase-modal-title {
  color: var(--deep-blue-main);
  font-family: 'IvyPresto-Regular';
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 0.46px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.Purchase-modal-legend {
  align-self: center;
  color: var(--darkest-grey);
  display: flex;
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.Purchase-modal-pet-row {
  color: var(--deep-blue-main);
  display: flex;
  flex-direction: row;
  font-family: 'IvyEpic-Regular';
  padding-top: 30px;
  width: 100%;
}

.Purchase-modal-pet-row img {
  padding-right: 16px;
}

.Purchase-modal-pill {
  align-items: center;
  border-radius: 10px;
  border-width: 1px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 4px 8px;
}

.Purchase-modal-pill-icon {
  border-radius: 8px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  height: 16px;
  margin-right: 5px;
  text-align: center;
  font-weight: 700;
  width: 16px;
}

.Purchase-modal-pill-icon::after {
  content: '!';
}

.Purchase-modal-pill[data-status=complete] .Purchase-modal-pill-icon::after {
  content: '✓';
}

.Purchase-modal-pill-text {
  font-weight: 700;
  padding-right: 5px;
}

.Purchase-modal-pill[data-status=pending] {
  background: #e8c39a99;
}

.Purchase-modal-pill[data-status=pending] .Purchase-modal-pill-icon {
  background: rgb(255, 163, 0);
}

.Purchase-modal-pill[data-status=error] {
  background: #F5D3C5;
}

.Purchase-modal-pill[data-status=error] .Purchase-modal-pill-icon {
  background: #c80707;
  color: #fff;
}

.Purchase-modal-pill[data-status=progress] {
  background: #f8ebafaa;
}

.Purchase-modal-pill[data-status=progress] .Purchase-modal-pill-icon {
  background: #eecc38;
  color: #01313d;
}

.Purchase-modal-pill[data-status=complete] {
  background: #bdece9aa;
}

.Purchase-modal-pill[data-status=complete] .Purchase-modal-pill-icon {
  background: #a9e5e2;
  color: #01313d;
}

/* Mobile */
@media (max-width: 568px) {
  .Purchase-Multi-Card-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 45%;
    max-width: unset;
  }

  .Purchase-Multi-Card-container-with-3 {
    max-height: 55%;
  }

  .Purchase-Multi-Card-container-with-more {
    max-height: 75% !important;
  }

  .Purchase-modal-title,
  .Purchase-modal-legend {
    text-align: left;
  }
}
