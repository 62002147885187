.Phone-iphone {
  border-radius: 50px;
  max-width: var(--phone-width);
  overflow: hidden;
  position: relative;
}

.Phone-iphone-screen {
  height: calc(var(--phone-height) - 40px) !important;
  width: calc(var(--phone-width) - 42px) !important;
}

.Phone-iphone > img {
  border-radius: 50px;
  height: var(--phone-height);
  position: absolute;
  top: 0;
  width: var(--phone-width);
}

.Phone-video {
  border-radius: 57px;
  margin: -5px 0 0 -10px;
  overflow: hidden;
  width: calc(var(--phone-width) + 34px);
}

.Phone-screenshot {
  position: absolute;
}

/* Tablet - Big */
@media (max-width: 1200px) and (min-width: 711px) {
  .Phone-screenshot {
    margin: 64px 0 35.83px;
  }

  .Phone-iphone-screen {
    height: calc(var(--phone-height) - 28px) !important;
    width: calc(var(--phone-width) - 34px) !important;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Phone-screenshot {
    margin: 0 auto;
    position: static;
  }

  .Phone-iphone {
    margin: auto;
  }

  .Phone-iphone-screen {
    height: calc(var(--phone-height) - 28px) !important;
    width: calc(var(--phone-width) - 37px) !important;
  }

  .Phone-iphone > img {
    border-radius: 36px;
    margin: 0 auto;
  }
}
