.Selector-pill-wrapper {
  background: #fff;
  border-radius: 8px;
  border: 1px solid var(--deep-blue-main);
  color: var(--deep-blue-main);
  display: flex;
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  padding: 11.5px 18px;
  position: relative;
}

.Selector-pill-wrapper[data-checked=true] {
  background-color: var(--citron-main);
  font-family: 'IvyEpic-Bold';
  padding: 11.5px 18px 11.5px 15px;
}

.Selector-pill-wrapper[data-disabled=true] {
  background-color: #d9dee1;
  color: var(--deep-blue-shade-2);
}

.Selector-pill-wrapper > img {
  height: 11px;
  margin-right: 5px;
  margin-top: 1.5px;
  width: 13px;
}

.Selector-pill-button {
  bottom: 0;
  cursor: pointer;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
}
