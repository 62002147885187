.Icon-tooltip-icon-default {
  cursor: pointer;
  height: 14px;
  margin-left: 2px;
  margin-top: 3px;
  width: 14px;
}

.Icon-tooltip-main-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 130px;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  width: 250px;
  z-index: 2;
}

.Icon-tooltip-description-container {
  align-items: center;
  background-color: var(--blue-main);
  border-radius: 8px;
  display: flex;
  height: 95%;
  justify-content: center;
  overflow: hidden;
  padding: 5px;
  width: 240px;
}

.Icon-tooltip-description {
  color: var(--deep-blue-main) !important;
  font-family: 'IvyEpic-Regular' !important;
  font-size: 13px !important;
  text-align: center;
}

.Icon-tooltip-arrow-container {
  display: flex;
  height: 10%;
  margin: -1px;
  width: 100%;
}

.Icon-tooltip-arrow-container-center {
  justify-content: center;
}

.Icon-tooltip-arrow-container-start {
  justify-content: flex-start;
  margin-left: 48px;
}

.Icon-tooltip-arrow-container-end {
  justify-content: flex-end;
  margin-right: 48px;

}

.Icon-tooltip-icon-button {
  background-color: transparent;
  border-radius: 50%;
  height: 18px;
  padding: 0;
  width: 18px;
}

.Icon-tooltip-arrow-down {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--blue-main);
  margin-top: -1px;
  height: 0;
  width: 0;
}

.Icon-tooltip-arrow-down-border {
  margin-right: -20px;
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;
}
