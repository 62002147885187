html, body {
  scroll-padding-bottom: 20rem;
}

.App {
  display: flex;
  flex-direction: column;
  margin-bottom: -24px;
}

.App-body {
  background-color: transparent;
  background-image: url(./assets/bg.png);
  background-size: cover;
  display: flex;
  justify-content: center;
}

.App-body-quote {
  align-items: center;
  flex-direction: column;
  position: relative;
}

.App-card-container {
  background-color: var(--ivory-shade-3);
  border-radius: 12px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.20);
  height: 500px;
  margin-top: -82px;
  width: 736px;
}

.App-side-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 48px 32px 0 0;
  width: 352px;
}

.focus-visible,
.data-focus-visible-added {
  outline: #2fb3b4 1px solid !important;
}

.Gone {
  display: none !important;
}

.Mobile {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--ivory-shade-2) inset !important;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .App {
    width: 100%;
  }

  .No-tablet {
    display: none !important;
  }

  .App-side-container {
    background-color: var(--ivory-shade-3);
    display: flex;
    margin: 0;
    padding-top: 32px;
    width: 100%;
  }

  .App-body {
    background-color: var(--ivory-shade-3);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .App {
    width: 100%;
  }

  .App-body {
    background-color: var(--ivory-shade-3);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }

  .App-side-container {
    background-color: var(--ivory-shade-3);
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 0;
  }

  .Mobile {
    display: block;
  }
}
