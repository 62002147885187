.LiveVet-NewCustomer-container-card {
  background-color: var(--ivory-shade-3);
  border-radius: 16px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
  margin: 42px;
  overflow: hidden;
  position: relative;
  width: 672px;
}

.LiveVet-NewCustomer-container {
  display: flex;
  flex-direction: column;
  margin: 40px 60px 40px 42px;
}

.LiveVet-NewCustomer-container-div {
  flex: 1;
  margin-top: 24px;
}

.LiveVet-NewCustomer-container-div-left {
  flex: 1;
}

.LiveVet-NewCustomer-container-div-left h3 {
  color: var(--deep-blue-main);
  font-family: 'IvyPresto-Regular';
  font-size: 24px;
  margin: 0;
  padding: 0;
}

.LiveVet-NewCustomer-container-div-left p {
  color: #4a545d;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 8px 0 0 0;
}
 
.LiveVet-NewCustomer-image {
  border-bottom-left-radius: 16px;
  margin: 0px;
  position: absolute;
  right: 0;
  top: 18px;
  width: 240px;
}

.LiveVet-NewCustomer-label-error {
  color: #cc0000;
  font-family: 'Inter-Medium';
  font-size: 11px;
  margin-top: 2px;
  padding-left: 10px;
}

.Livevet-NewCustomer-login-button {
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  height: 16px;
  outline-color: #2fb3b4;
  position: absolute;
  right: 20px;
  top: 25px;
  width: 16px;
}

.Livevet-NewCustomer-login-button img {
  height: 16px;
  width: 16px;
}

.LiveVet-NewCustomer-input-wrapper {
  display: flex;
  flex-direction: column;
  height: 42px;
  margin-bottom: 32px;
}

.LiveVet-NewCustomer-input-container {
  background-color: transparent;
  border-color: var(--deep-blue-shade-3);
  border-style: none none solid none;
  border-width: 1px;
  color: var(--deep-blue-main);
  font-family: 'Inter-Medium';
  font-size: 16px;
  outline: none;
  padding: 4px 0 6px 0;
  text-indent: 8px;
}

.LiveVet-NewCustomer-input-container:focus {
  border-color: var(--blue-shade-dark);
}

.LiveVet-NewCustomer-input-container:hover {
  border-color: var(--blue-shade-dark);
}

.LiveVet-NewCustomer-input-container::placeholder {
  color: var(--hinted-text);
}

.LiveVet-NewCustomer-input-error {
  border-color: #cc0000;
}

.LiveVet-NewCustomer-TextInfo {
  width: 346px;
}

.LiveVet-Pet-parent-input-label {
  color: var(--deep-blue-main);
  display: flex;
  font-family: 'IvyEpic-Bold';
  font-size: 14px;
  line-height: 21px;
}

.LiveVet-NewCustomer-login {
  display: flex;
  justify-content: space-between;
}

.NewCustomer-container-div {
  display: flex;
  flex-direction: column;
  margin-top: 45px;
}

.NewCustomer-container-div .Checkbox-container {
  width: auto;
}

.NewCustomer-container-div .NewCustomer-requirements-title {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Bold';
  font-size: 14px;
  margin-bottom: 12px;
}

.NewCustomer-container-div div p {
  margin: 0;
}

.NewCustomer-requirement {
  color: #6d7579;
  font-size: 14px;
  position: relative;
  width: auto;
}

.New-customer-requirement-container + .New-customer-requirement-container {
  margin-top: 16px;
}


/* Tablet - Big */
@media (min-width: 568px) and (max-width: 1400px) {
  .LiveVet-NewCustomer-container-card {
    width: 100%;
  }
}

/* Very specific case */
@media (max-width: 628px) {
  .LiveVet-NewCustomer-TextInfo {
    width: 300px;
  }
}

@media (max-width: 568px) {
  .LiveVet-NewCustomer-container-card {
    width: 90%;
    margin-bottom: 24px;
  }

  .LiveVet-NewCustomer-image {
    display: none;
  }

  .LiveVet-NewCustomer-container-div-left h3 {
    font-family: 'IvyEpic-Bold';
    text-align: center;
  }

  .LiveVet-NewCustomer-TextInfo {
    text-align: center;
    width: auto;
  }

  .LiveVet-NewCustomer-login {
    flex-direction: column;
    margin-top: 15px;
  }

  .LiveVet-NewCustomer-login .Pet-parent-input-container {
    margin: 0;
    width: 100%;
  }

  .LiveVet-NewCustomer-login .Pet-parent-input-container + .Pet-parent-input-container {
    margin-top: 22px;
  }

  .NewCustomer-container-div {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
  }

  .LiveVet-NewCustomer-container {
    display: flex;
    flex-direction: column;
    margin: 24px 32px 32px;
  }
}
