.Need-To-Know-container-section {
  display: flex;
  justify-content: center;
  width: 100%;
}

.Need-To-Know-container {
  background: var(--ivory-shade-2);
  border-radius: 18px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  height: 334px;
  margin-top: 24px;
  margin-bottom: 24px;
  padding-bottom: 20px;
  width: 736px;
}

.Need-To-Know-title {
  color: var(--deep-blue-main);
  font-family: 'IvyPresto-Regular';
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.4px;
  line-height: 46px;
  width: 100%;
}

.Need-To-Know-titleContainer{
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 48px 0 48px;
  width: 100%;
}

.Need-To-Know-Desktop-Image{
  margin-right: 10%;
  height: 189px;
  width: 50%;
}

.Need-To-Know-Mobile-Image {
  display: none;
}

.Need-To-Know-links-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  width: 100%;
}

.Need-to-know-links-list {
  margin: 0;
  padding: 0;
}

.Need-To-Know-link-container {
  background-color: transparent;
  border-bottom: 1px solid;
  color: var(--deep-blue-main);
  cursor: pointer;
  display: flex;
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  justify-content: space-between;
  line-height: 21px;
  padding: 16px 0 16px 0;
  width: 100%;
}

.Sidebar-help-modal-container {
  cursor: pointer;
}

.Sidebar-help-modal-content {
  border-radius: 8px;
}

.Need-To-Know-Help-link-text {
  pointer-events: none;
}

ul.Need-to-know-links-list>li {
  border-bottom: 1px solid #000;
}

ul.Need-to-know-links-list>li:nth-last-child(-n+1) {
  border-bottom: unset;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Need-To-Know-container {
    box-shadow: none;
  }
}

/* Tablet */
@media (max-width: 700px) {
  .Need-To-Know-container {
    align-items: center;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    height: 95%;
    width: 95%;
  }

  .Need-To-Know-titleContainer{
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0 24px 0 24px;
    width: 100%;
  }
  
  .Need-To-Know-Desktop-Image{
    display: none;
    margin-right: 10%;
    width: 40%;
  }

  .Need-To-Know-Mobile-Image {
    display: block;
    padding-top: 40px;
    width: 100%;
  }

  .Need-to-know-main-container {
    justify-content: center;
  }

  .Need-To-Know-title {
    display: flex;
    font-size: 32px;
    justify-content: center;
    line-height: 27px;
  }

  .Need-To-Know-links-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 24px 24px 24px;
    width: 85%;
  }

  .Need-To-Know-link-container {
    background-color: transparent;
    cursor: pointer;
    display: block;
    font-size: 16px;
    white-space: nowrap;
  }

  .Need-To-Know-link-container:nth-last-child(-n+1){
    border-bottom: none;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Need-To-Know-container {
    border-radius: 18px;
    border: 1.5px solid var(--deep-blue-main);
    box-shadow: none;
    height: 90%;
    width: 90%;
  }

  .Need-to-know-innered-card-enrollment {
    order: 3;
    padding-top: 40px;
  }

  .Need-To-Know-title {
    color: var(--deep-blue-main);
    display: flex;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    justify-content: center;
    letter-spacing: -0.4px;
    line-height: 31px;
    margin-bottom: 16px;
  }

  .Need-To-Know-Desktop-Image {
    display: none;
  }

  .Need-To-Know-Mobile-Image {
    margin-left: 10%;
    padding-top: 40px;
    width: 80%;
  }
  
  .Need-To-Know-titleContainer{
    padding: 0;
  }
  
  .Need-To-Know-links-container {
    margin: 0;
    padding: 0;
    width: 76%;
  }

  .Need-To-Know-link-container:nth-last-child(-n+1){
    border-bottom: none;
  }
}
