.My-pets-container {
  margin-top: 21px;
}

.My-pets-title-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 10px 0 13px 0;
}

.My-pets-discount {
  color: var(--deep-blue-shade-2);
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  margin: 14px 0 0 0;
  text-align: center;
  width: 100%;
}

.My-pets-add-pet-button-enrollment{
  align-items: center;
  background-color: var(--blue-main);
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  outline-color: #22435e;
  width: 191px;
}

.My-pets-add-pet-button {
  align-items: center;
  background-color: var(--blue-main);
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  outline-color: #22435e;
  width: 137px;
}

.My-pets-add-icon {
  height: 20px;
  margin-right: 8px;
  width: 20px;
}

.My-pets-add-pet-button-text {
  color: var(--deep-blue-main);
  font-family: 'Inter-SemiBold';
  font-size: 16px;
  pointer-events: none;
}

/* Mobile */
@media (max-width:568px) {
  .My-pets-discount {
    width: 75%;
  }
}
