.Landing-questions-container {
  background-color: var(--blue-main);
  padding: 70px 14% 76px;
}

.Landing-questions-title-container {
  display: flex;
  margin: 0 auto;
  max-width: 1025px;
}

.Landing-question-ill {
  height: 177px;
  margin-right: 9.36%;
  transform: scaleX(-1);
  width: 175px;
}

.Landing-questions-title-container h2 {
  color: var(--main-text);
  font-family: 'IvyPresto-Regular';
  font-size: 72px;
  letter-spacing: -1.07px;
  line-height: 86px;
  margin: 0;
  text-align: center;
}

.Landing-questions-questions {
  margin: 93px auto 0;
  max-width: 1025px;
}

.Landing-questions-question-list {
  margin: 0;
  padding: 0 20px;
}

/* Huge desktop */
@media (min-width: 1700px) {
  .Landing-questions-container {
    padding: 70px 20% 76px;
  }
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Landing-questions-container {
    padding: 86px 8.6% 92px;
  }

  .Landing-questions-title-container {
    align-items: center;
    flex-direction: column;
  }

  .Landing-question-ill {
    height: 144.72px;
    margin-right: 0;
    width: 143px;
  }

  .Landing-questions-title-container h2 {
    font-size: 50px;
    line-height: 50px;
    margin-top: 31.28px;
  }

  .Landing-questions-questions {
    margin-top: 67px;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Landing-questions-container {
    padding: 70px 7.4% 69px;
  }

  .Landing-question-ill {
    height: 119px;
    width: 117px;
  }

  .Landing-questions-title-container h2 {
    font-size: 36px;
    line-height: 46px;
    margin-top: 31.28px;
  }

  .Landing-questions-questions {
    margin-top: 48px;
  }

  .Landing-questions-question-list {
    padding: 0 0 0 20px;
  }
}
