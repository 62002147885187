.Plan-section-text {
  color: var(--deep-blue-shade-2);
  font-family: 'IvyEpic-Regular';
  font-size: 12px;
  line-height: 18px;
  margin: 8px 0 4px;
  max-width: 96%;
  text-align: left;
}

.Plan-section-prepackaged-plans {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px 0;
  width: 72%;
}

.Plan-section-log {
  height: 0;
  overflow: hidden;
}

/* Tablet - Medium */
@media (max-width: 710px) {
  .Plan-section-prepackaged-plans {
    width: 96%;
  }
}