.Pet-parent-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.Pet-parent-title {
  align-self: flex-start;
  color: var(--main-text);
  display: flex;
  font-family: 'IvyPresto-Regular';
  font-size: 36px;
  letter-spacing: -0.4px;
  line-height: 46px;
  margin: 24px 0;
  text-align: center;
}

.Pet-parent-input-container {
  display: flex;
  flex-direction: column;
  height: auto !important;
  min-height: 70px;
  position: relative;
  width: 288px;
}

.Pet-parent-inputs-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: auto !important;
  min-height: 70px;
  min-width: 320px;
  position: relative;
}

.Pet-parent-paragraph-container {
  display: flex;
  flex-direction: column;
  min-height: 70px;
  position: relative;
  width: 288px;
}

.Pet-parent-input-container-left {
  margin-right: 32px;
}

.Pet-parent-state-zip-container {
  display: flex;
  flex-direction: row;
}

.Pet-parent-state-zip-inputs {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 16px;
}

.Pet-parent-state-input-container {
  display: flex;
  flex-direction: column;
  min-height: 72px;
  width: 164px;
  margin-right: 16px;
}

.Pet-parent-state-input {
  display: flex;
  flex-direction: column;
  min-height: 72px;
  width: 168px;
}

.Pet-parent-zip-input-container {
  display: flex;
  flex-direction: column;
  min-height: 72px;
  width: 108px;
}

.Pet-parent-zip-input {
  display: flex;
  flex-direction: column;
  min-height: 72px;
  width: 134px;
}

.Pet-parent-paragraph-container {
  display: flex;
  flex-direction: column;
  margin: 0 0 4px 8px;
  width: 256px;
}

.Pet-parent-email-info-paragraph {
  font-family: 'IvyEpic-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #4A545D;
  position: absolute;
}

.Pet-parent-input-row {
  display: flex;
}

.Pet-parent-inputs-row {
  display: flex;
  gap: 32px;
  margin-bottom: 8px;
  width: 100%;
}

.Pet-parent-input-size {
  flex: none !important;
  min-width: 320px;
}

.Pet-parent-inputs-last-row {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.Pet-parent-input-label {
  color: var(--main-text);
  display: flex;
  flex-direction: column;
  font-family: 'IvyEpic-Bold';
  font-size: 14px;
  line-height: normal;
  margin: 0 0 4px 8px;
  position: relative;
}

.Pet-parent-input-label-with-icon {
  flex-direction: row;
}

.Pet-parent-input {
  background-color: transparent;
  border-color: var(--deep-blue-shade-2);
  border-radius: 0;
  border-style: none none solid none;
  border-width: 1px;
  caret-color: #2fb3b4;
  color: var(--deep-blue-shade-2);
  font-family: 'Inter-Medium';
  font-size: 16px;
  outline-width: 0;
  padding: 6px 0 6px 0;
  text-indent: 8px;
}

.Pet-parent-input::placeholder {
  color: var(--hinted-text);
}

.Pet-parent-input:hover {
  border-color: var(--blue-shade-dark);
}

/* Safari 9.0 and up support */
@supports (-webkit-hyphens:none) {
  input[type="password"] {
    font-size: 12px;
    line-height: normal;
  }
}

.Pet-parent-label-error {
  color: var(--error);
  font-family: 'Inter-Medium';
  font-size: 11px;
  padding-left: 9px;
  margin-top: 2px;
}

.Pet-parent-input-error {
  border-color: var(--error);
}

.Pet-parent-input-error:hover {
  border-color: var(--error) !important;
}

.Pet-parent-input:focus {
  border-color: var(--blue-shade-dark);
}

.Pet-parent-input:hover:not(:focus):not(:read-only) {
  border-color: #7e888c;
  border-radius: 0.5px;
  border-style: none none solid none;
  border-width: 1px;
  border-image: none;
}

.Pet-parent-input:read-only {
  background-color: #d9dee1;
  border-radius: 5px;
  border: none;
}

.Pet-parent-input::placeholder {
  font-family: 'Inter-Regular';
  font-size: 16px;
  line-height: 1.25;
  color: #6d7579;
}

.Pet-parent-input-icon-container {
  background-color: #d9dee1;
  border-radius: 5px;
  display: flex;
  padding: 8px 0 8px 8px;
}

.Pet-parent-input-with-icon {
  background-color: transparent;
  border: none;
  color: var(--deep-blue-shade-2);
  font-family: 'Inter-Medium';
  font-size: 15px;
  min-width: 0;
}

.Pet-parent-lock-icon {
  margin-right: 13px;
}

.Pet-parent-dropdown-icon {
  height: 10px;
  margin-right: 13px;
  width: 16px;
}

.Second-parent-button-container {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 32px 0 20px;
  outline-color: #2fb3b4;
}

.Second-parent-plus-icon {
  height: 20px;
  margin-right: 8px;
  width: 20px;
}

.Second-parent-button-title {
  color: var(--main-text);
  font-family: 'IvyEpic-Bold';
  font-size: 16px;
}

.Second-parent-form-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
}

.Second-parent-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Second-parent-brief-description {
  color: var(--deep-blue-shade-2);
  font-family: 'IvyEpic-Regular';
  line-height: 24px;
  margin-bottom: 32px;
  text-align: center;
  width: 544px;
}

.Employee-id-info {
  align-self: center;
  display: flex;
  left: 158px;
  position: absolute;
  top: -2px;
}

.Employee-id-info-button {
  align-items: center;
  bottom: 35px;
  cursor: pointer;
  display: flex;
  height: 16px;
  outline-color: #2fb3b4;
  position: absolute;
  right: 20px;
  width: 16px;
}

.Employee-id-info-button img {
  pointer-events: none;
}

.Second-parent-button-span-container {
  display: inline-grid;
  justify-content: center;
}

.Pet-parent-email-label-error {
  color: var(--error);
  font-family: 'Inter-Medium';
  font-size: 11px;
  margin: 2px 0 12px;
  padding-left: 10px;
}

.Pet-parent-input-last-row-container {
  display: grid;
  grid-template-areas:
    "phone email1"
    "employee email2";
  grid-template-rows: 1fr 1fr;
}

.Pet-parent-input-last-row:nth-child(1) {
  grid-area: phone;
}

.Pet-parent-input-last-row:nth-child(2) {
  grid-area: email1;
}

.Pet-parent-input-last-row:nth-child(3) {
  grid-area: email2;
}

.Pet-parent-input-last-row:nth-child(4) {
  grid-area: employee;
}

/* Tablet - Medium */
@media (max-width: 710px) {
  .Pet-parent-container {
    width: 100%;
  }

  .Pet-parent-input-container-left {
    margin-right: 0;
  }

  .Pet-parent-input-container {
    width: 100%;
  }
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Pet-parent-title {
    margin-top: 0;
  }
}

/* Tablet */
@media (max-width: 700px) {
  .Pet-parent-inputs-container {
    flex: 1;
    min-width: 250px;
    width: auto;
  }

  .Pet-parent-state-input {
    width: 40%;
  }

  .Pet-parent-zip-input {
    width: 30%;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Pet-parent-container {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .Pet-parent-title {
    font-size: 24px;
    margin: 0 0 24px 0;
    text-align: center;
  }

  .Pet-parent-inputs-container {
    width: 100%;
  }

  .Pet-parent-input-row {
    flex-direction: column;
    flex-wrap: wrap;
    width: 85%;
  }

  .Pet-parent-inputs-row {
    gap: 0;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
  }

  .Pet-parent-state-zip-container {
    margin-bottom: 18px;
  }

  .Pet-parent-state-input-container {
    min-height: 56px;
    max-height: 56px;
    width: 154px;
    margin-right: 8px;
  }

  .Pet-parent-state-input {
    flex: 2;
    width: 168px;
  }

  .Pet-parent-zip-input-container {
    min-height: 56px;
    max-height: 56px;
    width: 110px;
  }

  .Pet-parent-zip-input {
    flex: 1;
    width: 134px;
  }

  .Second-parent-button-container {
    margin-bottom: 18px !important;
  }

  .Second-parent-form-container {
    align-items: center;
    flex-direction: column;
    margin-bottom: 48px;
    width: 100%;
  }

  .Second-parent-form {
    align-items: center;
    flex-direction: column;
    margin-bottom: 48px;
    width: 100%;
  }

  .Second-parent-brief-description {
    font-size: 14px;
    line-height: 1.429 !important;
    margin: 0 25px 25px 24px !important;
    width: 270px !important;
  }

  .Pet-parent-lock-icon {
    height: 14px;
    margin-top: 4px;
    width: 12px;
  }

  .Employee-id-info {
    align-self: center;
    display: flex;
    margin-left: 25px;
    margin-top: 1px;
    position: absolute;
  }

  .Pet-parent-email-info-paragraph {
    margin: 0px auto 0;
    text-align: center;
    width: auto;
  }

  .Pet-parent-input-last-row-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "phone"
      "email1"
      "employee"
      "email2";
    width: 85%;
  }

  .Pet-parent-input-last-row:nth-child(3) {
    grid-area: employee;
  }

  .Pet-parent-input-last-row:nth-child(4) {
    grid-area: email2;
  }

  .Pet-parent-email-label-error {
    margin-bottom: 15px;
  }
}
