.Modal-remove-pet-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 320px;
  justify-content: flex-start;
  width: 381px;
}

.Modal-remove-pet-image {
  height: 64px;
  width: 64px;
  margin-top: 32px;
}

.Modal-remove-pet-title {
  color: var(--deep-blue-main);
  font-family: 'Inter-SemiBold';
  font-size: 22px;
  margin-bottom: 0;
  margin-top: 16px;
}

.Modal-remove-pet-description {
  color: var(--deep-blue-shade-2);
  font-family: 'Inter-Regular';
  font-size: 16px;
  height: 45px;
  margin-top: 12px;
  text-align: center;
  width: 325px;
}

.Modal-remove-footer-buttons-container {
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: space-between;
  margin-top: 29px;
  width: 316px;
}

.Modal-remove-footer-button {
  border-radius: 8px;
  border-style: solid;
  cursor: pointer;
  font-family: 'Inter-SemiBold';
  font-size: 16px;
  height: 100%;
  outline-color: #2fb3b4;
  width: 150px;
}

.Modal-remove-footer-cancel-button {
  background-color: #eceef0;
  color: var(--deep-blue-main);  
}

.Modal-remove-footer-remove-button {
  background-color: var(--deep-blue-main);
  border-color: #fff;
  color: var(--ivory-main);
}
