.One-inc-modal {
  margin: auto;
  max-width: 350px;
  text-align: center;
}

.One-inc-add-payment-button {
  align-items: center;
  background-color: var(--blue-main);
  border-radius: 30px;
  color: var(--main-text);
  display: flex;
  font-family: 'IvyEpic-Bold';
  height: 48px;
  justify-content: center;
  padding: 0 22px;
}

.One-inc-add-payment-button:hover {
  cursor: pointer;
}

.One-inc-add-payment-button img {
  height: 25px;
  margin-right: 8px;
  width: 33px;
}

.One-modal-details {
  align-items: center;
  display: flex;
  justify-content: center;
}

.One-modal-details-left {
  display: flex !important;
  flex-direction: row !important;
  min-width: 200px;
}

.One-modal-detail-update-style {
  background: #f9f1e8;
  border-radius: 8px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  padding: 18px;
}

.One-modal-details-image {
  height: 36px;
}

.One-modal-details div {
  display: flex;
  flex-direction: column;
  margin: 0 12px !important;
}

.One-modal-details-button {
  background-color: transparent;
  color: #22435e;
  cursor: pointer;
  font-family: 'Inter-SemiBold';
  font-size: 14px;
  font-weight: bold;
  min-height: 30px;
}

.One-modal-details-button-update {
  font-size: 16px !important;
  text-decoration: underline;
}

.One-modal-details strong {
  color: #1e2226;
  font-family: 'Inter-Medium';
  font-size: 14px;
  text-align: left;
}

.One-modal-last-digits {
  color: #3d484d;
  font-family: 'Inter-Regular';
  font-size: 14px;
}

.One-modal-details div span:last-child {
  color: #7e888c;
  font-family: 'Inter-Regular';
  font-size: 12px;
}

.One-modal-button-select {
  background: none;
  border: 2px solid #fff;
  margin-bottom: 10px;
  padding: 8px;
}

.One-modal-button-select:hover {
  background: #b8ede8;
  border-radius: 9px;
  border: 2px solid #d9dee1;
  cursor: pointer;
  opacity: 0.9;
}

.One-inc-radio-button-container {
  display: flex;
}

.One-inc-radio-button-container input {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #eceef0;
  border-radius: 100%;
  border: 2px solid #eceef0;
  cursor: pointer;
  height: 20px;
  margin-right: 10px;
  min-width: 20px;
  position: relative;
  top: 4px;
  width: 20px;
}

.One-inc-radio-button-container input:checked {
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-name: fadeInOpacity;
  animation-timing-function: ease-in;
  background: no-repeat center url("../../assets/check.svg");
  border: transparent !important;
  height: 20px;
  min-width: 20px;
  opacity: 1;
  width: 20px;
}

.One-inc-radio-button-container input:focus {
  outline-color: #2fb3b4;
}

.One-inc-radio-button-container label {
  color: #3d484d;
  cursor: pointer;
  display: flex;
  font-family: 'Inter-Medium';
  font-size: 17px;
}

.One-inc-modal-warning-container {
  background-color: #b8ede8;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  margin-top: 31px;
  padding: 18px 16px;
  width: 400px;
}

.One-inc-modal-warning-container img {
  height: 20px;
  width: 20px;
}

.One-inc-modal-warning-container p {
  margin: 0;
  padding: 0;
}

.One-inc-modal-warning-right {
  color: #3d484d;
  font-family: 'Inter-Medium';
  font-size: 13px;
  margin: 0 16px;
}

.One-inc-modal-warning-right button {
  align-items: center;
  background-color: transparent;
  color: #22435e;
  cursor: pointer;
  display: flex;
  font-family: 'Inter-SemiBold';
  justify-content: center;
  margin-top: 7px;
  padding: 0;
}

.One-inc-modal-warning-right button img {
  height: 12px;
  padding-right: 7px;
  width: 12px;
}

.One-inc-add-payment-button-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.One-inc-button-loading {
  height: 30px;
  position: relative;
  width: 30px;
}

.One-inc-button-loading div {
  position: absolute;
  left: -47px;
}

.One-inc-section-warning {
  align-items: flex-start;
  background-color: var(--blue-shade-3);
  border-radius: 8px;
  color: var(--deep-blue-main);
  display: flex;
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  gap: 16px;
  line-height: 21px;
  margin-top: 16px;
  max-width: 292px;
  padding: 16px 34px;
  text-align: left;
}

.Purchase-result-fail-button-container {
  display: flex;
  justify-content: center;
}

.Purchase-result-fail-button {
  background: var(--deep-blue-main);
  border-radius: 32px;
  color: #fff;
  cursor: pointer;
  font-family: 'Inter-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  padding: 13px 23px;
}

/* Mobile */
@media (max-width: 568px) {
  .One-inc-modal-warning-container {
    width: auto;
  }
}
