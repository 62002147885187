.Quoting-header-container {
  align-items: flex-start;
  background-color: var(--header-background);
  display: flex;
  flex-direction: column;
  height: 226px;
  justify-content: center;
  position: sticky;
  top: -138px;
  z-index: 3;
}

.Quoting-header-logo-icon {
  height: 44px;
  margin: 24px 0 20px 0;
  width: 132px;
}

.Quoting-header-container-row-top {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  position: sticky;
  top: 16px;
  width: 100%;
}

.Steps-container-fixed {
  margin: 0 100px 0 40px !important;
}

.Quoting-header-container-steps-container {
  align-items: center;
  background-blend-mode: multiply;
  background-color: var(--header-background);
  display: flex;
  margin: 0 calc(50% - 405px);
  width: 650px;
}

.Quoting-header-step-container {
  align-items: center;
  display: flex;
  cursor: pointer;
  flex-direction: column;
}

.Quoting-header-step-completed-image {
  height: 24px;
  pointer-events: none;
  width: 24px;
}

.Quoting-header-step-circle {
  align-items: center;
  background-color: var(--deep-blue-shade-3);
  border-radius: 12px;
  pointer-events: none;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.Quoting-header-step-circle-completed {
  background-color: var(--blue-main);
}

.Quoting-header-step-number {
  color: #fefefe;
  font-family: 'Inter-SemiBold';
  font-size: 14px;
}

.Quoting-header-step-circle-completed > span {
  color: #22435e;
}

.Quoting-header-step-text {
  color: #fff;
  font-size: 12px;
  margin-top: 12px;
  pointer-events: none;
}

.step-progress-line {
  background-color: var(--deep-blue-shade-2);
  border-radius: 1px;
  flex: 1;
  height: 2px;
  margin-bottom: 25px;
}

.step-progress-line-half {
  background: linear-gradient(90deg, #6cd3cd 50%, #22435e 50%);
}

.step-progress-line-full {
  background-color: var(--blue-main);
}

.Quoting-header-quote-total-wrapper {
  bottom: 0;
  position: absolute;
  width: 280px;
  z-index: 1;
}

.Quoting-header-quote-total-container {
  align-items: center;
  background-color: var(--citron-main);
  border-radius: 8px 8px 0 0;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.02);
  display: flex;
  height: 88px;
  justify-content: space-between;
  margin-right: 0px;
  width: 251px;
}

.Quoting-header-quote-total-flat {
  border-radius: 0px;
}

.Quoting-header-my-quote {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Bold';
  font-size: 18px;
  line-height: 27px;
  margin: 34px 0 34px 24px;
}

.Quoting-header-quote-currency-container {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
}

.Quoting-header-my-quote > img {
  display: none;
}

.Quoting-header-quote-currency,
.Quoting-header-quote-currency-small,
.Quoting-header-quote-monthly {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Bold';
}

.Quoting-header-quote-currency {
  display: flex;
  font-size: 32px;
  justify-content: flex-end;
  line-height: 32px;
}

.Quoting-header-quote-currency-small {
  font-size: 16px;
  letter-spacing: -0.8px;
  line-height: 24px;
}

.Quoting-header-quote-currency-big {
  font-size: 32px;
  letter-spacing: -0.89px;
  line-height: 32px;
  margin-top: 4px;
}

.Quoting-header-quote-monthly {
  font-size: 16px;
  line-height: 24px;
  text-align: end;
}

.Quoting-header-link {
  text-decoration: none;
}

/* Tablet - Big */
@media (min-width: 568px) and (max-width: 1200px) {
  .Quoting-header-info-row {
    justify-content: center !important;
    margin: 0;
  }

  .Quoting-header-quote-total-container {
    display: none;
  }

  .Quoting-header-container {
    align-items: center;
    height: 216px;
    justify-content: center;
    top: -108px;
    width: 100%;
  }

  .Quoting-header-container-steps-container {
    display: none;
    margin: 0;
  }

  .Quoting-header-container-row-top {
    align-items: center;
    flex: unset;
    height: fit-content;
    justify-content: center;
    margin: 0;
    position: unset;
    top: 0;
    width: 100%;
  }

  .Quoting-header-quote-total-container {
    background-color: #182f43;
    border-radius: 0;
    height: auto;
    justify-content: flex-start;
    width: auto;
  }

  .Quoting-header-quote-currency,
  .Quoting-header-my-quote,
  .Quoting-header-quote-currency-small,
  .Quoting-header-quote-currency-big
  {
    font-size: 24px;
    height: auto;
    margin: 0;
  }

  .Quoting-header-quote-currency {
    margin-right: 4px;
  }

  .Quoting-header-quote-total-wrapper {
    width: auto;
  }

  .Quoting-header-quote-monthly {
    font-size: 14px;
    text-align: start;
    width: auto;
  }

  .Quoting-header-quote-currency-container {
    align-items: center;
    flex-direction: row;
    margin: 0 0 0 6px;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Quoting-header-container {
    height: 136px;
    justify-content: flex-start;
    top: -49px;
    width: 100%;
  }

  .Quoting-header-container-steps-container {
    display: none;
    margin: 0;
  }

  .Quoting-header-info-row {
    justify-content: center !important;
    margin: 0;
  }

  .Quoting-header-quote-total-container {
    display: none;
  }

  .Quoting-header-container-row-top {
    align-items: center;
    flex: unset;
    height: fit-content;
    justify-content: center;
    margin: 0;
    position: unset;
    top: 0;
    width: 100%;
  }

  .Quoting-header-quote-total-container {
    background-color: #182f43;
    border-radius: 0;
    height: 21px;
    justify-content: flex-start;
    padding: 4px 0px;
    width: 100%;
  }

  .Quoting-header-quote-currency,
  .Quoting-header-my-quote,
  .Quoting-header-quote-currency-small,
  .Quoting-header-quote-currency-big
  {
    font-size: 18px;
    height: 21px;
    margin: 0;
  }

  .Quoting-header-my-quote {
    margin-left: 10px;
  }

  .Quoting-header-quote-total-wrapper {
   display: none;
  }

  .Quoting-header-quote-monthly {
    font-family: 'Inter-Regular';
    height: 15px;
    font-size: 12px;
    text-align: start;
    margin-left: 2px;
    width: auto;
  }

  .Quoting-header-quote-currency-container {
    align-content: flex-end;
    align-items: center;
    flex-direction: row;
    margin: 0 0 0 42px;
  }
}
