.Prepackaged-plan-card {
  align-items: flex-start;
  background-color: #ffffffbf;
  border-radius: 5px;
  border: 2px solid var(--deep-blue-shade-3);
  color: var(--deep-blue-shade-2);
  display: flex;
  flex-direction: column;
  font-family: 'IvyEpic-Regular';
  width: 100%;
}

.Prepackaged-plan-data p {
  color:  #4a545d;
}

.Prepackaged-plan-data div > span, .Prepackaged-plan-price > span {
  color: var(--deep-blue-main);
}

.Prepackaged-plan-card[data-checked=true] {
  background-color: var(--blue-shade-3);
  border: 2px solid var(--deep-blue-main);
}

.Prepackaged-plan-card > .Like-span {
  display: flex;
  width: 100%;
  padding: 16px 20px 16px 24px;
}

.Prepackaged-plan-data {
  width: 69%;
}

.Prepackaged-plan-name {
  align-items: center;
  display: flex;
  font-family: 'IvyEpic-Bold';
  letter-spacing: 1px;
  line-height: 16px;
  text-align: left;
  text-transform: uppercase;
}

.Prepackaged-plan-name span {
  margin-top: 3px;
}

.Prepackaged-plan-name img {
  margin-right: 8px;
}

.Prepackaged-plan-desc {
  font-size: 12px;
  line-height: 18px;
  margin: 4px 0 0;
  text-align: left;
}

.Prepackaged-plan-price {
  align-items: flex-end;
  align-self: flex-start;
  display: flex;
  flex: 1;
  font-family: 'IvyEpic-Bold';
  font-size: 18px;
  justify-content: flex-end;
  position: relative;
}

.Prepackaged-plan-super {
  align-self: flex-start;
  font-size: 12px;
}

.Prepackaged-plan-sub {
  align-self: flex-end;
  font-family: 'IvyEpic-Regular';
  font-size: 10px;
  line-height: 14px;
}

.Prepackaged-plan-hands-up {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 12px 0 0 0;
  text-align: center;
  width: 71%;
}

.Prepackaged-bottom-disclaimer {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  line-height: 24px;
  margin: 18px 19px 14px 19px;
  text-align: left;
}

/* Handle hover effect only on devices with pointing device */ 
@media (hover: hover) {
  .Prepackaged-plan-card > .Like-span:hover {
    cursor: pointer;
  }

  .Prepackaged-plan-card:hover {
    background-color: var(--blue-shade-3);
    border: 2px solid var(--deep-blue-main);
  }
}

/* Tablet - Medium */
@media (max-width: 700px) {
  .Prepackaged-plan-hands-up {
    width: 85%;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Prepackaged-plan-card {
    border-radius: 5px;
    padding: 0;
    width: 100%;
  }

  .Prepackaged-plan-card > .Like-span {
    padding: 0;
    padding: 16px;
    width: 100%;
  }

  .Prepackaged-plan-data {
    width: 70%;
  }

  .Prepackaged-plan-name {
    font-size: 14px;
  }

  .Prepackaged-plan-desc {
    font-size: 10px;
    line-height: 12px;
    margin: 5px 0 0;
  }
}
