.Live-vet-info-main-container {
  align-items: center;
  background-color: var(--ivory-shade-2);
  border-radius: 12px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 736px;
  z-index: 0;
}

.Live-vet-purchase-button-container {
  align-items: center;
  background-color: var(--deep-blue-main);
  border-radius: 30px;
  color: var(--ivory-shade-3);
  cursor: pointer;
  display: flex;
  font-family: 'Inter-SemiBold';
  font-size: 16px;
  height: 60px;
  justify-content: center;
  line-height: 19px;
  margin-bottom: 40px;
  width: 327px;
}

/* Mobile */
@media (max-width: 568px) {
  .Live-vet-info-main-container {
    border-radius: 0;
    margin: 0;
    width: 100%;
  }
}
