.Your-info-agreements-constainer {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 78%;
}

.Your-info-agreements-constainer p {
  margin: 0;
}

.Your-info-agreements-constainer label {
  align-items: center;
  color: var(--deep-blue-shade-2);
  cursor: pointer;
  display: flex;
  font-family: 'IvyEpic-Regular';
  font-size: 13px;
  padding-bottom: 8px;
}

.Terms-condition-checkbox {
  width: 500px;
}

.Terms-condition-checkbox > .Checkbox-content {
  display: block;
}

.Terms-condition-checkbox-container {
  gap: 8px;
}

.Terms-condition-checkbox-container input {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #eceef0;
  border-radius: 100%;
  border: 2px solid #eceef0;
  cursor: pointer;
  height:20px;
  margin-right: 10px;
  min-width: 20px;
  position: relative;
  top: 4px;
  width: 20px;
}

.Terms-condition-checkbox-container input:checked {
    background: no-repeat center url("../../../assets/check.svg");
    border: transparent !important;
    height: 20px;
    width: 20px;
    min-width: 20px;
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.3s;
}

.Terms-condition-checkbox-container input:focus {
  outline-color: #2fb3b4;
}
