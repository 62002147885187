.DeductibleReimbursement-container {
  display: flex;
  min-height: 60px;
  width: 71%;
}

.Deductible-reimbursement-container {
  display: flex;
  min-height: 60px;
  width: 100%;
}

.DeductibleReimbursement-container-div {
  display: flex;
  width: 100%;
}

.Deductible-reimbursement-wrapper {
  flex-direction: column;
  row-gap: 9px;
}

.Deductible-legend {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  line-height: 24px;
  margin: 0 19px;
  text-align: left;
}

.Deductible-legend-div {
  align-items: flex-start;
  display: flex;
  width: calc(100% - 36px);
  padding: 0 18px;
}

.Deductible-legend-text {
  color: var(--deep-blue-shade-2);
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  line-height: 24px;
  margin: 12px 0 0 0;
  text-align: center;
}

.Deductible-legend-text-div {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 90%;
}

/* Mobile */
@media (max-width: 568px) {
  .DeductibleReimbursement-container-div {
    width: 97%;
  }

  .Deductible-reimbursement-wrapper {
    flex-wrap: wrap;
    width: 100%;
  }

  .DeductibleReimbursement-container {
    margin-bottom: 0;
    min-height: 190px !important;
    width: 100% !important;
  }

  .Deductible-legend {
    text-align: left;
  }
}
