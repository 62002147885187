.Saved-quote-expired-container {
  background-color:  #fbf6f1;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.Saved-quote-logo {
  height: 64px;
  left: 40px;
  position: absolute;
  top: 40px;
  width: 172px;
}

.Saved-quote-content-row {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 25vh;
  z-index: 1;
}

.Saved-quote-content-row > img {
  height: 180px;
}

.Saved-quote-content-row > h1 {
  color: #01313d;
  font-family: 'IvyEpic-Regular';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin: 40px 0 24px 0;
}

.Saved-quote-content-row > span {
  color: #01313d;
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
}

.Saved-quote-content-row > button {
  background: #01313d;
  border-radius: 30px;
  color: #fbf6f1;
  font-family: 'Inter-Regular';
  font-size: 16px;
  font-style: normal;
  height: 60px;
  line-height: 19px;
  padding: 11.2px 25.2px 12.2px 25.6px;
  width: 336px;
}

.Saved-quote-footer-container {
  bottom: 0;
  position: absolute;
  width: 100%;
}

/* Mobile */
@media (max-width: 568px) {
  .Saved-quote-content-row {
    margin-top: 20vh;
  }

  .Saved-quote-content-row > h1 {
    font-size: 30px;
    margin-top: 50px;
  }

  .Saved-quote-content-row > span {
    font-size: 16px;
    margin-bottom: 40px;
  }

  .Saved-quote-content-row > button {
    width: 296px;
  }

  .Saved-quote-logo {
    left: unset;
    top: 24px;
    width: 100%;
  }
}
