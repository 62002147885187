.Pet-cloud-section-as-card {
  margin-top: 32px;
}

.Pet-cloud-section-as-card .Pet-cloud-section-setup {
  margin-bottom: 25px;
}

.Pet-cloud-section>h3 {
  color: #01313d;
  font-family: 'IvyPresto-Regular';
  font-size: 24px;
  letter-spacing: -0.4px;
  line-height: 1.6;
  line-height: 31px;
  margin: 0;
}

.Pet-cloud-section-content {
  display: flex;
  flex-wrap: wrap;
}

.Pet-cloud-section-sms {
  max-width: 500px;
}

.Pet-cloud-section-text-me-a-link-complete {
  color: #01313d;
  cursor: default;
  font-family: 'IvyPresto-Regular';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  max-width: 50px;
}

.Pet-cloud-section-text-me-a-link {
  color: #22435e;
  cursor: pointer;
  font-family: 'Inter-SemiBold';
  font-size: 16px;
}

.Pet-cloud-section-input-phone {
  background-color: #fbf6f1;
  border-radius: 26px;
  border: 2px solid #737a83;
  color: #3d484d;
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  height: 52px;
  margin-right: 16px;
  outline-color: #2fb3b4;
  text-align: center;
  width: 167px;
}

.Pet-cloud-section-button-no-style {
  background-color: transparent;
}

.Pet-cloud-section-desktop-login {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  justify-items: center;
  margin-bottom: 24px;
}

.Pet-cloud-login-as-footer {
  justify-content: flex-end;
  width: 100%;
}

.Pet-cloud-section-desktop-text {
  color: #293742;
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  margin: 0px;
}

.Pet-cloud-section-desktop-link {
  color: #182f43;
  font-family: 'Inter-SemiBold';
  font-size: 16px;
  margin-bottom: 5px;
  text-decoration: none;
}

.Pet-cloud-section-app-store {
  height: 44px;
  width: 145px;
}

.Pet-cloud-section-google-play {
  height: 44px;
  width: 145px;
}

.Pet-cloud-section-setup {
  color: #293742;
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
}

.Pet-cloud-section-apps {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.Pet-cloud-section-stores {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 8px;
}

.Pet-cloud-section-options {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.Pet-cloud-section-complete-close-window {
  align-items: center;
  color: #3d484d;
  display: flex;
  font-family: 'Inter-Medium';
  font-size: 16px;
  justify-content: center;
  margin-bottom: 200px;
  margin-top: 32px;
}

.Pet-cloud-section-complete-close-window img {
  height: 20 px;
  margin-right: 8px;
  width: 20px;
}

.Pet-cloud-section-text-me {
  align-items: center;
  display: flex;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Pet-cloud-section-content {
    flex-direction: column;
  }

  .Pet-cloud-section-options {
    align-items: flex-start;
    margin-top: 24px;
  }

  .Pet-cloud-section-desktop-login {
    margin-bottom: 8px;
  }

  .Pet-cloud-login-as-footer {
    justify-content: flex-start;
    width: auto;
  }

  .Pet-cloud-section-apps {
    width: 100%;
  }

  .Pet-cloud-section-stores {
    margin: 0;
  }

  .Pet-cloud-section-app-store {
    margin: 0 16px 0 0;
  }

  .Pet-cloud-section-qr {
    bottom: 0;
    position: absolute;
    right: 0;
    padding-right: 5px;
  }

  .Pet-cloud-text-me-as-card {
    align-items: flex-start;
    flex-direction: column;
    margin-top: 24px;
  }

  .Pet-cloud-text-me-as-card>button {
    margin: 14px 0 8px;
  }

  .Pet-cloud-text-me-as-card .Pet-cloud-section-text-me-a-link-complete,
  .Pet-cloud-text-me-as-card .Pet-cloud-section-text-me-a-link {
    margin: 0 0 0 29px;
  }

  .Pet-cloud-apps-as-card {
    margin-top: 16px;
  }

  .Pet-cloud-apps-as-card>.Pet-cloud-section-stores {
    flex-direction: column-reverse;
  }

  .Pet-cloud-apps-as-card .Pet-cloud-section-google-play {
    margin-right: 50px;
  }

  .Pet-cloud-apps-as-card .Pet-cloud-section-qr {
    position: relative;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Pet-cloud-section {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .Pet-cloud-section-as-card {
    margin-top: 40px;
  }

  .Pet-cloud-section-content {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .Pet-cloud-section-setup {
    text-align: center;
  }

  .Pet-cloud-section-text-me {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .Pet-cloud-section-input-phone {
    margin: 0 0 12px;
  }

  .Pet-cloud-section-options {
    margin-top: 32px;
  }

  .Pet-cloud-section-apps {
    flex-direction: column !important;
  }

  .Pet-cloud-section-stores {
    margin: 0 0 24px 0;
  }

  .Pet-cloud-section-qr {
    align-self: center;
    order: 1;
    width: 96px;
  }

  .Pet-cloud-section-app-store {
    margin: auto;
  }

  .Pet-cloud-section-google-play {
    margin: 16px auto 21px;
  }

  .Pet-cloud-section-desktop-login {
    display: none;
  }
}
