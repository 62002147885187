.Save-your-quote-modal-main-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 462px;
  padding: 0 36px;
}

.Save-your-quote-modal-icon {
  height: 48px;
  margin-top: 32px;
}

.Save-your-quote-modal-title {
  color: var(--deep-blue-main);
  font-family: 'Inter-SemiBold';
  font-size: 22px;
  line-height: 27px;
  margin-top: 17.41px;
  margin-bottom: 0;
}

.Save-your-quote-modal-subtitle {
  color: var(--deep-blue-shade-2);
  font-size: 13px;
  margin-bottom: 36px;
  margin-top: 8px;
  text-align: center;
  width: 256px;
}

.Save-your-quote-input-container {
  height: 10px;
  max-height: 56px;
}

.Email-your-quote-buttons-container {
  display: flex;
  margin-bottom: 20px;
}

.Save-your-quote-save-button {
  background-color: var(--deep-blue-main);
  border-radius: 8px;
  color: var(--ivory-main);
  font-family: 'Inter-SemiBold';
  font-size: 16px;
  height: 48px;
  margin-top: 15px;
  width: 102px;
}

.Save-your-quote-save-button-cancel {
  background-color: #eceef0;
  color: var(--deep-blue-main);
  margin-right: 6px;
}
