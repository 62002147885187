.Multi-modal-info-container {
  display: flex;
  height: 480px;
  width: 736px;
}

.Multi-modal-info-col-left {
  align-items: center;
  background-color: var(--ivory-shade-3);
  border-radius: 10px 0px 0px 10px;
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 288px;
}

.Multi-modal-info-figo-logo {
  height: 60px;
  margin-top: 32px;
  width: 48px;
}

.Multi-modal-info-title {
  color: #22435e;
  font-family: 'Inter-SemiBold';
  font-size: 24px;
  margin-top: 12px;
}

.Multi-modal-info-menu-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  overflow-y: auto;
  scroll-behavior: auto;
  width: 100%;
}

.Multi-modal-info-menu-item {
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  height: 52px;
  justify-content: flex-start;
  min-height: 52px;
  outline-color: #2fb3b4;
  width: 100%;
}

.Multi-modal-info-menu-item:hover {
  background-color: rgba(1, 49, 61, 0.2);
}

.Multi-modal-info-menu-item-selected {
  align-items: center;
  background-color: var(--deep-blue-main) !important;
  display: flex;
  height: 52px;
  justify-content: flex-start;
  min-height: 52px;
  width: 100%;
}

.Multi-modal-info-menu-item-selected:hover {
  background-color: rgba(47, 179, 180, .9);
}

.Multi-modal-info-item-title {
  color: var(--deep-blue-main);
  font-family: 'Inter-SemiBold';
  font-size: 16px;
  padding-left: 24px;
  pointer-events: none;
}

.Multi-modal-info-item-title-selected {
  color: var(--ivory-main);
  font-family: 'Inter-SemiBold';
  font-size: 16px;
  padding-left: 24px;
}

.Multi-modal-info-col-right {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 0px 10px 10px 0px;
  width: 448px;
}

.Multi-modal-info-content-container {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 480px;
  width: 385px;
}

.Multi-modal-info-content-title {
  color: var(--deep-blue-main);
  padding-top: 30.5px;
}

.Multi-modal-info-content-image-container {
  align-items: center;
  background-color: #eceef0;
  display: flex;
  height: 96px;
  justify-content: center;
  margin-top: 23.5px;
  min-height: 96px;
  width: 100%;
}

.Multi-modal-info-content-image {
  height: 100%;
  width: 100%;
}

.Multi-modal-info-content-rows-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 16px;
  padding: 0;
  width: 100%;
}

.Multi-modal-info-content-row,
.Multi-modal-info-content-row-single {
  align-items: center;
  border-bottom-width: 1px;
  border-color: #d9dee1;
  border-style: none none solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: fit-content;
  padding: 10px 0 10px;
  margin-bottom: 1px;
  width: 100%;
}

.Multi-modal-info-content-row-single {
  border-style: none;
}

.Multi-modal-info-content-row-icon {
  height: 8px;
  margin: 5px 8px 0 0;
  width: 8px;
}

.Multi-modal-info-content-row-cross-icon {
  height: 20px;
  margin: 0 8px 0 0;
  width: 20px;
}

.Multi-modal-info-content-row-bullet {
  filter: invert(57%) sepia(13%) saturate(3327%) hue-rotate(321deg) brightness(92%) contrast(90%);
}

.Multi-modal-info-content-row-title {
  color: var(--deep-blue-main);
  width: 100%;
}

.Multi-modal-info-content-row-title-enrollment {
  color: var(--deep-blue-main) !important;
  font-family: 'Inter-Regular';
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.Multi-modal-info-content-row-value {
  color: var(--deep-blue-main);
  min-width: fit-content;
}

.Multi-modal-info-content-bullet-value {
  color: var(--deep-blue-shade-2);
}

.Multi-modal-info-content-row-top {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Multi-modal-info-content-row-middle {
  display: flex;
  width: 100%;
}

.Multi-modal-info-content-row-subtitle {
  color: #6d7579;
  font-size: 14px;
}

.Multi-modal-info-element-gone {
  display: none;
}

.Multi-modal-info-green-label {
  color: var(--deep-blue-main);
  font-family: 'Inter-SemiBold';
  text-decoration: underline;
}

.Modal-card-button-close-icon {
  height: 20px;
  width: 20px;
}

.Multi-modal-info-content-row-subtitle-enrollment {
  color: #6d7579;
  font-size: 14px;
  font-weight: 500;
}

.Multi-modal-info-content-subtitle {
  color: var(--deep-blue-shade-2);
  font-size: 14px;
  line-height: 16.94px;
  margin-top: 18.5px;
}

/*Tablet and Mobile shared styles*/
@media (max-width: 1200px) {
  .Multi-modal-info-content-container {
    align-items: center;
    background-color: var(--ivory-shade-3);
    justify-content: flex-start;
    width: 100%;
  }

  .Multi-modal-info-content-image-container {
    display: none;
  }

  .Multi-modal-info-col-left {
    display: none;
  }

  .Multi-modal-info-col-right {
    background-color: var(--ivory-shade-3);
    margin-left: 16px;
    width: 100%;
  }

  .Multi-modal-info-content-rows-container {
    margin-top: 32px;
  }

  .Content-scrollable-y {
    margin-right: 8px;
  }

  .Content-scrollable-y::-webkit-scrollbar {
    width: 10px;
  }
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Multi-modal-card-container {
    border-radius: 8px;
    height: 59%;
    width: 50%;
  }

  .Multi-modal-info-container {
    height: 95%;
    justify-content: center;
    overflow: hidden;
    width: 100%;
  }

  .Multi-modal-info-content-title {
    font-size: 24px;
  }

  .Modal-card-button-close-icon {
    height: 32px;
    width: 32px;
  }

  .Multi-modal-info-content-row-title {
    font-size: 15px;
  }

  .Multi-modal-info-content-row-subtitle {
    font-size: 14px;
    margin-top: 8px;
    padding: 0;
  }

  .Scrollbar-margin::-webkit-scrollbar-track {
    margin-top: 90px;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Multi-modal-card-container {
    border-radius: 24px 24px 0 0;
    height: 75%;
    position: absolute;
    width: 100%;
  }

  .Multi-modal-info-container {
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .Multi-modal-info-content-title {
    font-size: 22px;
    padding-top: 32px;
  }

  .Modal-card-button-close-icon {
    height: 20px;
    width: 20px;
  }

  .Multi-modal-info-content-row-title {
    font-size: 14px;
  }

  .Multi-modal-info-content-row-subtitle {
    font-size: 14px;
    padding: 0;
  }

  .Scrollbar-margin::-webkit-scrollbar-track {
    margin-top: 73px;
  }
}
