.Powerup-title-container-optional,
.Powerup-title-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 24px;
  width: 100%;
}

.Powerup-title-container-optional h3,
.Powerup-title-container h3 {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Bold';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  max-width: 219px;
}

.Powerup-title-container-optional h3 {
  font-size: 21px;
  margin-top: 24px;
  max-width: 250px;
}

.Powerup-right-content {
  align-items: center;
  background-color: var(--citron-main);
  border-radius: 16px;
  color: var(--deep-blue-main);
  display: flex;
  font-family: 'IvyEpic-Bold';
  font-size: 12px;
  justify-content: center;
  padding: 3px 8px;
}

.Powerup-right-content span {
  margin: 4px 0 0 8px;
}

.Powerup-added {
  background-color: var(--blue-shade-dark);
}

.Powerup-switch {
  margin-left: 16px;
}

.Powerup-title {
  align-items: center;
  display: flex;
}

.Powerup-title h3 {
  margin-right: 16px;
}

.Powerup-title-container-optional > .Powerup-title {
  justify-content: space-between;
  width: 100%;
}

.Powerup-optional {
  font-family: 'IvyPresto-Italic';
  font-size: 21px;
  font-weight: 400;
  margin-right: 8px;
}

/* Mobile */
@media (max-width: 568px) {
  .Powerup-title-container h3 {
    font-size: 14px;
  }

  .Powerup-title-container-optional,
  .Powerup-title-container { 
    margin-bottom: 16px;
  }

  .Powerup-optional,
  .Powerup-title-container-optional h3 {
    font-size: 16px;
  }
}
