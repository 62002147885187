.Content-scrollable-y {
  margin-right: 3px;
  overflow-y: auto;
  scroll-margin: 0;
  scrollbar-color: var(--deep-blue-main) #eceef0;
  scrollbar-width: thin;
}

.Scrollbar-margin {
  scroll-margin: 10px 0 10px 1px;
}

.Content-scrollable-y::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.Content-scrollable-y::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 5px #eceef0;
}

.Scrollbar-margin::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 5px #eceef0;
  margin: 10px 0 10px 0;
}

.Content-scrollable-y::-webkit-scrollbar-thumb {
  background: var(--deep-blue-main);
  border-radius: 4px;
}

.Content-scrollable-y::-webkit-scrollbar-thumb:hover {
  background: #b8ede8; 
}
