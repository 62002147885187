.See-full-coverage-details-container {
    padding: 25px;
  }

  .See-full-coverage-details-title {
    color: var(--deep-blue-main);
    cursor: pointer;
    font-family: 'IvyEpic-Bold';
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
  }

  .See-full-coverage-details-container button {
    background: none;
  }

  .See-full-coverage-details-underline {
    border-bottom: 4px solid var(--citron-shade-1);
    position: relative;
    bottom: 17px;
  }
