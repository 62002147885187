.Section-title {
  color: var(--main-text);
  font-family: 'IvyPresto-Regular';
  font-size: 72px;
  line-height: 86px;
  margin: 45px 0 32px;
  text-align: center;
  white-space: break-spaces;
}

.Section-title-no-top-margin {
  margin-top: 0;
}

/* Tablet - Big */
@media (max-width: 1200px) and (min-width: 711px) {
  .Section-title {
    font-size: 42px;
    font-size: 50px;
    line-height: 50px;
    margin-top: 48px;
    padding: 0 9.6%;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Section-title {
    font-size: 36px;
    line-height: 1.167;
    margin-top: 48px;
    padding: 0 5%;
  }
}
