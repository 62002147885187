.Dropdown-container {
  display: flex;
  flex-direction: column;
  min-height: 41px;
  position: relative;
  width: 280px;
}

.Dropdown-header {
  align-content: center;
  align-items: center;
  border-color: var(--deep-blue-shade-2);
  border-style: none none solid none;
  border-width: 1px;
  color: #6d7579;
  cursor: pointer;
  display: flex;
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  height: 41px;
  justify-content: space-between;
  outline-color: var(--deep-blue-main);
  width: 100%;
}

.Dropdown-header:focus {
  outline: var(--deep-blue-main) 1px solid;
}

.Dropdown-header-error {
  border-color: #cc0000;
}

.Dropdown-header-error-message {
  color: #cc0000;
  font-family: 'Inter-Medium';
  font-size: 11px;
  padding: 2px 1px 0 10px;
}

.Dropdown-selected-item-text {
  color: #3d484d;
  left: 0;
}

.Dropdown-selected-item {
  align-items: center;
  background-size: 16px;
  display: flex;
  height: 100%;
  pointer-events: none;
  text-indent: 5px;
  width: 100%;
}

.Dropdown-list-overlayed-container:not(.Dropdown-list) {
  align-items: center;
  background-color: transparent;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  outline-color: var(--deep-blue-main);
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}

.Dropdown-list {
  background-color: var(--ivory-shade-3);
  border-radius: 0 0 8px 8px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, .08);
  display: flex;
  flex-direction: column;
  margin-top: 53px;
  max-height: 261px;
  overflow-y: auto;
  padding-bottom: 4px;
  position: absolute;
  top: 0px;
  left: -1.2px;
  transition: all 0.3s ease;
  width: 280px;
  z-index: 2;
}

.Dropdown-list>ul {
  margin: 0px;
  padding: 0px;
}

.Dropdown-list-hidden {
  opacity: 0;
  pointer-events: none;
  top: -10px;
}

.Dropdown-list-item-container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 5px 10px 2px 9px;
  min-height: 30px;
  outline-color: var(--deep-blue-main);
}

.Dropdown-list-item {
  align-items: center;
  background-color: var(--ivory-shade-3);
  border-color: var(--ivory-shade-3);
  border-radius: 4px;
  color: #3d484d;
  cursor: pointer;
  display: flex;
  font-family: 'Inter-Light';
  font-size: 13.33px;
  height: 36px;
  justify-content: flex-start;
  outline-color: var(--deep-blue-main);
  padding: 0px 5px;
  text-align: left;
  width: 100%;
}

.Dropdown-list-item:hover {
  background-color: rgba(184, 237, 232, 0.5);
  border-color: rgba(184, 237, 232, 0.5);
  outline-color: rgba(184, 237, 232, 0.5);
}

.Dropdown-list-item-selected {
  background-color: #b8ede8;
  border-color: #b8ede8;
}

.Dropdown-arrow-closed {
  height: 10px;
  position: absolute;
  right: 3%;
  transition: all 0.3s ease;
  width: 16px;
}

.Dropdown-arrow-open {
  transform: rotate(180deg);
}

.Dropdown-arrow-container {
  outline-color: var(--deep-blue-main);
}

.Dropdown-search-input {
  align-items: center;
  background-color: transparent;
  border-style: none;
  color: #3d484d;
  display: flex;
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  height: 20px;
  justify-content: center;
  outline-color: var(--deep-blue-main);
  pointer-events: all;
  position: relative;
  text-indent: 3px;
  width: 100%;
}

.Dropdown-search-input::placeholder,
.Dropdown-selected-item-placeholder {
  color: var(--hitend-text);
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  left: 0;
  line-height: 24px;
  opacity: 1;
}

.Input-container {
  appearance: auto;
  box-sizing: border-box;
  letter-spacing: normal;
  line-height: normal;
  margin: 0em;
  padding: 1px 3px;
  text-align: center;
  text-shadow: none;
  text-transform: none;
  word-spacing: normal;
}


.Dropdown-container-landing {
  width: 100% !important;
}

.Dropdown-container-landing .Dropdown-list {
  width: 100%;
}

/* Mobile */
@media (max-width: 568px) {
  .Dropdown-container-landing {
    width: 100% !important;
  }
}

/* Safari 9.0 and up support */
@supports (-webkit-hyphens:none) {
  .Dropdown-container-error-safari {
    height: auto;
    margin-bottom: -14px;
  }
}