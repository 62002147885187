.Step {
  background-color: var(--ivory-main);
  border-radius: 30px;
  border: 1px solid var(--deep-blue-main);
  display: flex;
  max-width: 324px;
  padding: 27px 38px 19px 26px;
}

.Step img {
  align-self: flex-start;
  margin-right: 20px;
}

.Step h3 {
  color: var(--main-text);
  font-family: 'IvyEpic-Bold';
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 8px;
}

.Step p {
  color: var(--main-text);
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  line-height: 1.6;
  margin: 0;
}

/* Tablet - Big */
@media (max-width: 1200px) and (min-width: 569px) {
  .Step {
    max-width: 298.2px;
    padding: 23.22px 15px 23.22px 20.33px;
  }

  .Step img {
    margin-right: 11.71px;
    width: 53px;
  }

  .Step h3 {
    font-size: 14.66px;
  }

  .Step p {
    font-size: 12.83px;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Step {
    padding: 30px 26px 21px 22px;
  }
}
