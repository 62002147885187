.Landing-underwritted {
  background-color: var(--header-background);
  color: var(--ivory-main);
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  line-height: 21px;
  padding: 6px 0;
  text-align: center;
}

.Landing-employee-header {
  align-items: center;
  background-color: var(--ivory-shade-3);
  box-shadow: rgba(0, 0, 0, 0.02) 0 5px 16px 0;
  display: flex;
  justify-content: space-between;
  padding: 15px 30px 15px 26px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

.Landing-logo-image-common {
  height: 45.35;
  width: 235px;
}

.Landing-get-quote-container {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

.Landing-employee-text {
  color: var(--main-text);
  font-family: 'IvyEpic-Bold';
  font-size: 14px;
  margin-right: 44px;
}

.Landing-logo-image {
  height: 60px;
  margin: 16px 0 8px 50px;
  max-width: 419px;
  width: 175px;
}

/* Tablet - Big */
@media (max-width: 1200px) and (min-width: 711px) {
  .Landing-underwritted {
    width: 100%;
  }

  .Landing-logo-image {
    margin: 16px 0 8px 0;
    z-index: 1;
  }
}

/* Tablet - Medium */
@media (max-width: 710px) {
  .Landing-employee-text {
    font-size: 18px;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Landing-header {
    align-items: center;
    justify-content: center;
  }

  .Landing-underwritted {
    font-size: 10px;
    line-height: 1.4;
    padding: 4px 60px;
  }

  .Landing-employee-header {
    align-items: flex-start;
    flex-direction: column;
    height: auto;
    justify-content: flex-start;
    padding: 0 0 0 22px;
    width: calc(100% - 22px);
  }

  .Landing-logo-image-common {
    height: 36px;
    margin: 16px 0 8px 0;
    width: 211px;
  }

  .Landing-get-quote-container {
    display: none;
  }

  .Landing-employee-text {
    font-size: 14px;
    margin: 8px 0 17px;
  }

  .Landing-logo-image {
    height: 36px;
    margin: 16px 0 8px 0;
    width: 106px;
  }
}
