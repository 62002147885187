.Figo-input-message-error,
.Figo-input-message-success,
.Figo-input-message-info {
  align-items: flex-start;
  border-radius: 4px;
  color: var(--midnight-base-500);
  display: flex;
  flex-direction: row;
  font-family: 'IvyEpic-Regular';
  font-size: 12px;
  gap: 4px;
  line-height: 18px;
  min-height: 24px;
  padding: 4px;
}

.Figo-input-message-error:empty,
.Figo-input-message-success:empty,
.Figo-input-message-info:empty {
  background-color: transparent;
}

.Figo-input-message-error {
  background-color: var(--red-100);
}
.Figo-input-message-success {
  background-color: var(--green-100);
}
.Figo-input-message-info {
  background-color: var(--evening-blue-100);
}
